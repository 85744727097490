.page-footer {
  padding: 28px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .logo-container {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;

    .logo {
      max-width: 120px;
    }
  }

  .copy {
    width: 100%;
    text-align: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: $dark-gray;
  }
}