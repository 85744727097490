//////////////////////////////
// CUSTOM BOOTSTRAP VARIABLES
//////////////////////////////

// mixins
// Convert pixels sizes
$base-size: 16;

@function size($target, $context: $base-size) {
  @return ($target / $context) * 1em;
}

$tenso: tenso, sans-serif;
$Inter: 'Inter', sans-serif;

$font-family-sans-serif: Helvetica, sans-serif;
$headings-font-family: $tenso;

$font-xs:  size(10);
$font-sm:  size(12);
$font-md:  size(14);
$font-lg:  size(16);
$font-xl:  size(18);
$font-xxl: size(20);

$border-radius-sm:  4px;
$border-radius:  8px;
$gbl-padding-sm: 8px;
$gbl-padding:    12px;
$gbl-padding-lg: 16px;




// Custom Colors
$white:           #ffffff;
$lightest-gray:   #FAFAFA;
$light-gray:      #C9C9C9;
$gray:            #9EA0A2;
$dark-gray:       #6B7280;
$bb-teal-lighter: #F4FBFA;
$bb-teal-light:   #A2D7CE;
$bb-teal:         #00C4B3;
$bb-teal-medium:  #1BAEA0;
$bb-teal-dark:    #01A899;
$bb-green:        #0F5E53;
$bb-green-alt:    #345D5A;
$bb-slate-light:  #607D8B;
$bb-slate:        #263238;
$orange:          #FF8228;
$yellow:          #FFCA28;
$blue:            #65C8FF;
$darker-blue:     #00A6FB;
$accent:          #E9F7F5;
$accent-2:        #EAEAEB;
$purple:          #B481EA;

$primary: $bb-teal;
//$secondary: transparent;
$bahama-orange: #f15c4c;
$bahama-teal: #00aba0;
$bahama-white: #FFFFFF;
$bahama-black: #000000;
$bahama-green: #00C991;

$gutters: 50px;


// Forms
$input-btn-focus-width:    1px;
$form-label-font-size:     $font-md;
$form-label-margin-bottom: size(4);
$form-label-font-weight:   500;
$form-label-color:         $bb-green;
//$form-label-font-style:                 null !default;

$input-padding-y: $font-lg;
$input-padding-x: $font-md;
$input-font-size: $font-sm;
//$input-font-family:                     $input-btn-font-family !default;
//$input-font-weight:                     $font-weight-base !default;
//$input-line-height:                     $input-btn-line-height !default;

//$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
//$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
//$input-font-size-sm:                    $input-btn-font-size-sm !default;
//
//$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
//$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
//$input-font-size-lg:                    $input-btn-font-size-lg !default;
//$input-color:                           $body-color !default;
$input-bg:           $lightest-gray;
$input-border-color: transparent;
//$input-border-width:                    $input-btn-border-width !default;
//$input-box-shadow:                      $box-shadow-inset !default;

$input-placeholder-color: $gray;

$input-border-radius:   50px;
$input-border-radius-sm: 50px;
$input-border-radius-lg: 50px;


// Buttons
$button-disabled: $light-gray;
$btn-border-radius:    4px;
$btn-border-radius-sm: 4px;
$btn-border-radius-lg: 4px;

$btn-padding-y:      8px;
$btn-padding-x:      16px;
$btn-font-family:    $tenso;
$btn-font-size:      16px;
$btn-line-height:    1;
//$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:   4px;
$btn-padding-x-sm:   8px;
$btn-font-size-sm:   14px;

$btn-padding-y-lg:   10px;
$btn-padding-x-lg:   24px;
$btn-font-size-lg:   18px;

$btn-link-color: $white;
$btn-link-hover-color: red;
$btn-hover-bg-shade-amount: 50%;
$accordion-button-active-bg: $bb-teal-lighter;
//$btn-border-width:   0;
//$grid-gutter-width: 0;



// CARDS
$card-border-width:  0;
$card-border-color:  $white;
$card-border-radius: $border-radius !default;
//$card-spacer-y:                     $spacer !default;
$card-spacer-x:                     10px !default;
$card-cap-padding-y: 14px !default;
$card-cap-padding-x: 10px !default;

// TRANSITIONS
$transition-duration: 0.3s;

//PAGINATION
$pagination-border-radius: 50%;
$pagination-active-color: $bb-green;
$pagination-active-bg: $bb-teal-light;
$pagination-color: $bb-slate-light;
$pagination-border-width: 0;
$pagination-hover-color: $bb-green;
$pagination-padding-x: 12px;
$pagination-padding-y: 5px;

//NAV TABS
$nav-tabs-link-active-color: $bb-teal-medium;
$nav-link-color: #75B0AB;
$nav-link-font-size: 14px;
$nav-tabs-border-width: 0;
$nav-link-padding-x: 0;
$nav-link-font-size: 16px;

//FONTS
$inter: Inter, Helvetica, Arial, sans-serif;
$helvetica: Helvetica, Arial, sans-serif;
$tenso: Tenso, Helvetica, Arial, sans-serif;


//////////////////////////////
// BOOTSTRAP IMPORT
//////////////////////////////
@import "bootstrap/custom-bootstrap";

//////////////////////////////
// SITE SCSS
//////////////////////////////
@import "fonts";
@import "global";
@import "header";
@import "buttons";
@import "cards";
@import "breadcrumb";
@import "forms";
@import "activity-complete";
@import "footer";
@import "calendar";
@import "utils";
@import "shop";
@import "mobile-nav";
@import "marketing";
@import "operations";
@import "desktop-nav";
@import "dashboard";
@import "people";
@import "franchise-family";
@import "mib";
@import "financial";
@import "news";
@import "brand-management";
@import "resources";


// LEADERBOARD
.leaderboard {
  .leader-podium {
    display: grid;
    grid-template-columns: 1fr 1.6fr 1fr;
    gap: $gbl-padding-lg;
    align-items: center;
    padding: 0 $gbl-padding;
    text-align: center;

    img {
      border-radius: 100px;
      border: 5px solid;
    }

    .leader-place {
      position: relative;
      top: -15px;
      background: $white;
      font-family: NATS, sans-serif;
      width: 28px;
      height: 28px;
      border-radius: 40px;
      margin: auto;
      font-size: size(18);
      line-height: 1;
      border: solid 5px;
    }

    .leader-name {
      font-family: Tenso, sans-serif;
      font-weight: bold;
      position: relative;
      top: -12px;
      z-index: 1;
    }

    .leader-name-shadow {
      color: $white;
      font-family: NATS, sans-serif;
      font-size: 24px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      z-index: 0;
    }

    .leader-you {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      font-size: size(12);
    }

    .leader-first {
      position: relative;
      img,
      .leader-place {
        border-color: $yellow;
      }
    }
    .leader-second {
      position: relative;
      img,
      .leader-place {
        border-color: $orange;
      }
    }
    .leader-third {
      position: relative;
      img,
      .leader-place {
        border-color: $blue;
      }
    }
  }

  .leader-tiers {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    text-align: center;
    align-items: flex-end;
    margin-top: 10px;

    .bar {
      border: 2px solid $white;
      border-radius: 8px 8px 0 0;
    }

    .tier-first .bar {
      background: linear-gradient(180deg, #FFCA28 0%, #FFEBAD 100%);
      height: 150px;
    }
    .tier-second .bar {
      background: linear-gradient(180deg, #FF8228 0%, #FFD0AD 100%);
      height: 130px;
    }
    .tier-third .bar {
      background: linear-gradient(180deg, #66C8FF 0%, #ADE2FF 100%);
      height: 120px;
    }

    h3 {
      color: $white;
      margin-top: 25px;
      font-weight: 700;

    }

    .tier-metric {
      display: inline-block;
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: $font-xs;
      color: $bb-teal;
      background: $white;
      padding: 4px;
      border-radius: $border-radius-sm;
    }
  }
}

// ROSTER LIST
.roster-list {
  padding: 0;
  list-style: none;

  li {
    display: grid;
    grid-template-columns: 55px 1fr .5fr;
    align-items: center;
    gap: $gbl-padding-sm;
    border-bottom: 1px solid #F4FBFA;
    padding: 12px 0;

    img {
      border-radius: 50px;
    }

    h4 {
      font-size: size(16);
      font-weight: 700;
      margin-bottom: 0;
      color: $bb-green;
    }

    p {
      margin-bottom: 0;
    }

    .rank {
      background: $bb-teal;
      color: $white;
      text-align: center;
      border-radius: $border-radius-sm;
      padding: 8px 12px;
      font-size: $font-sm;

      svg {
        margin-right: 4px;
      }
    }
  }
}

//PROFILE
.profile-nav {
  position: relative;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;

  .left, .right {
    display: flex;
    align-items: center;
  }

  .left {

    .menu {
      image {
        width: 16px;
        height: 10px;
      }
    }

    .logo {
      height: 16px;
    }
  }

  .right {

    .profile-btn {
      .profile-image {
        width: 32px;
        height: 32px;
        border-radius: 100%;
      }
    }
  }

  .profile-menu {
    position: absolute;
    top: 58px;
    right: 16px;
    z-index: 6;

    box-shadow: -10px 20px 44px rgba(38, 50, 56, 0.16);
    border-radius: 10px;

    &.disabled {
      display: none;
    }

    .info {

      .name {
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        color: $bb-green
      }

      .email {
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $bb-green
      }
    }

    .menu-btn {
      display: flex;
      align-items: center;
      border-top: 1px solid $accent;

      img {
        width: 20px;
      }

      .text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: $bb-green;
      }
    }
  }

  .profile-notifications {
    position: absolute;
    left: 13px;
    top: 58px;
    right: 60px;
    box-shadow: -10px 20px 44px rgba(38, 50, 56, 0.16);
    border-radius: 10px;
    z-index: 6;

    &.disabled {
      display: none;
    }

    &:active {
      opacity: 0.3;
    }

    .header {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $accent-2;
      font-family: 'Tenso';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;

      .title {
        color: $bb-green;
      }

      .read-all-btn{
        padding: 4px 8px;
        color: $bb-teal;
      }
    }

    .notification {
      padding: 12px 8px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $accent-2;

      .left-section {
        width: 40px;
        margin-right: 10px;

        .icon-wrapper {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          background-color: $bb-teal-light;

          img {
            width: 20px;
          }
        }
      }

      .right-section {
        width: calc(100% - 50px);
        .information {
          display: flex;
          justify-content: space-between;

          .notification-header {
            display: flex;
            flex-direction: column;
            margin-right: 10px;

            .title {
              font-family: 'Tenso';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 130%;
              color: $bb-green;
            }

            .subtitle {
              font-family: 'Helvetica';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: $bb-slate-light;
            }
          }

          .timestamp {
            font-family: 'Helvetica';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: $bb-slate-light;
          }
        }

        .preview {
          display: flex;
          margin-top: 12px;

          .thumbnail-container {
            position: relative;
            width: 60px;
            min-width: 60px;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 12px;

            img {
              width: 100%;
            }
          }

          .excerpt {
            padding-top: 6px;
            font-family: 'Tenso';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            color: $bb-green;
          }
        }
      }
    }

    .view-all-section {
      .view-all-btn {
        width: 100%;
        padding: 12px;
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        color: $bb-teal;
      }
    }
  }
}

// BREADCRUMB PROFILE
.profile-nav-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .crumb {
    display: flex;
    align-items: center;

    font-family: 'Tenso';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: $bb-teal-light;

    &.has-icon {
      padding-left: 0;
    }

    &.active {
      font-weight: 700;
      color: $bb-teal-medium;
    }

    .icon {
      width: 12px;
    }
  }
}

// BUCK'S ON DEMAND BANNER
.bucks-social-banner {
  width: 100%;
  display: flex;
  align-items: flex-end;
  min-height: 316px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .banner-info {
    width: 100%;
    height: 100%;
    background: linear-gradient(180.25deg, rgba(38, 50, 56, 0) 56.77%, rgba(38, 50, 56, 0.17) 75.85%, rgba(38, 50, 56, 0.69) 99.78%);

    .title {
      width: 100%;
      font-family: 'Tenso';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: white;
    }

    .about {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      line-height: 125%;
      color: white;
      font-style: normal;

      .left-content {
        display: flex;
        flex-wrap: wrap;

        .date {
          font-family: 'Helvetica';
          font-weight: 400;
        }

        .video-link {
          font-family: 'Helvetica';
          font-weight: 700;
          text-decoration: none;
          color: white;

          .link-icon {
            margin-left: 6px;
          }
        }
      }
    }
  }
}

// BUCK'S ON DEMAND CARDS
.bucks-social-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .social-card {
    width: calc(50% - 8px);
    display: block;
    text-decoration: none;
    margin-bottom: 24px;

    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }

    .thumbnail {
      height: calc(25vw - 24px);
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      border-radius: 12px;
      padding: 8px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .top, .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .top {
        .media-label {
          display: block;
          padding: 2px 10px;
          border-radius: 6px;
          background: rgba(244, 251, 250, 0.9);
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 8px;
          line-height: 1;
          color: $bb-teal-medium;
        }

        .like-btn {
          height: 16px;
          display: flex;
          align-items: flex-start;
          .btn-icon {
            width: 100%;
          }
        }
      }

      .bottom {
        align-self: flex-end;

        .duration {
          padding: 2px 8px;
          background: $dark-gray;
          border-radius: 4px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 8px;
          line-height: 12px;
          color: white;
        }
      }
    }

    .card-info {
      .title {
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        color: $bb-green;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .date {
        font-family: 'Helvetica';
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 14px;
        color: $bb-slate-light;
      }
    }
  }
}

// FINANCIAL
.financial-header {
  padding: 12px;

  .eyebrow {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: $bb-green;
  }

  .location-section {
    display: flex;
    align-items: center;

    .location-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background: rgba(0, 196, 179, 0.07);
      border-radius: 100%;

      img {
        width: 28px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      .title {
        font-family: 'VVDS_Halau';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 110%;
        color: $bb-green;
      }

      .location {
        display: flex;
        align-items: center;

        .map-icon {
          width: 11px;
          margin-right: 6px;
        }

        .text {
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: $bb-green;
        }
      }
    }
  }
}

.financial-stats {

  .stats-panel {
    display: none;

    &.active {
      display: block;
    }
  }

  .panel-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    .nav-btn {
      font-family: $headings-font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #75B0AB;
      text-align: center;
    }

    .active {
      font-weight: 700;
      padding-bottom: 7px !important;
      border-bottom: 1px solid $bb-teal-medium;
    }
  }

  .filter-btn {
    width: 100%;
  }

  .filter-section {
    .filter-search {
      margin-bottom: 12px;
    }
  }

  .results-section {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding: 0 -16px 0 -16px;
  }
}

.collapsible-table, .two-col-table {
  width: 100%;
  display: flex;
  flex-direction: column;

  .table-section {
    &.hidden {
      display: none;
    }

    .column-2 {
      width: calc(85% - 5px);
    }

    .column-3 {
      width: calc(15% - 5px);
    }

    @include media-breakpoint-up(sm) {
      .column-1 {
        width: calc(15% - 5px);
      }
      .column-2 {
        width: calc(70% - 5px);
      }
    }

    &.above-fold.active {
        .rows .row.alt-row {
          border-radius: 0;
        }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      background: $bb-teal-medium;
      border-radius: 8px 8px 0 0;
      font-family: 'Tenso';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: white;
    }

    .rows {
      .row {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        margin: 0;

        &.alt-row {
          background: $bb-teal-lighter;

          &:last-child {
            border-radius: 0 0 8px 8px;
          }
        }

        .column {
          font-size: 14px;
          line-height: 150%;
          font-style: normal;
        }

        .column-1 {
          font-family: 'Helvetica';
          font-weight: 400;
          color: $bb-slate;
        }
    
        .column-2 {
          font-family: 'Tenso';
          font-weight: 500;
          color: $bb-teal-medium;
        }
    
        .column-3 {
          font-family: 'Helvetica';
          font-weight: 400;
          color: $bb-slate-light;
        }

        .column {
          padding: 0;
        }
      }
    }
  }

  .expand-btn {
    width: 100%;
    display: none;

    &.visible {
      display: block;
    }
  }
}

.two-col-table {
  width: unset;
  .edit {
    .form-header {
      font-size: 14px;
      font-weight: 500;
    }
    svg:hover {
      cursor: pointer;
    }
  }
  .content-section-light {
    box-shadow: none;
  }
  .heading p {
    display: inline;
    &:first-child {
      margin-right: 10px;
    }
  }
  .column:not(.heading) {
    width: 50%;
  }
}


.profile-dashboard-header {

  .eyebrow {
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: $bb-green;
  }

  .header {
    font-family: 'VVDS_Halau';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: $bb-green;
  }
}

.profile-header, .shop-header {

  .title {
    width: 100%;
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: $bb-green;
  }

  .header-card {
    position: relative;

    .profile-img-container, .store-img-container {
      position: relative;
      //width: 31%;
      //max-width: 112px;

      img {
        width: 100%;
      }
    }

    .profile-info, .store-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //min-height: 100%;
      //width: 55%;
      //padding: 8.5px 0;

      .top {
        width: 100%;

        .name {
          font-family: $headings-font-family;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 130%;
          color: $bb-green;
        }

        .rank, .address {
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          color: $bb-green;
        }
      }

      .bottom {
        //width: 100%;
      }
    }
    //.store-img-container {
    //  width: unset;
    //}
    //.store-info {
    //  width: unset;
    //}
    .edit-buttons {
      place-self: center;
      svg:first-child {
        position: relative;
        right: -3px;
      }
    }
  }
}

.support-center-heading {
  padding: 12px 16px;
  text-align: center;

  .eyebrow {
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    color: $bb-teal-medium;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .title {
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: $bb-green;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .subtitle {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $bb-green;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.ask-us-anything-section {

  .aua-input {
    margin-bottom: 32px;
  }

  .facts-list {
    list-style: none;
    padding-left: 30px;
    margin: 16px 0;

    li {
      position: relative;

      &:before {
        position: absolute;
        left: -30px;
        content: "";
        background-image: url(/static/icons/question.svg);
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
      }

      &:not(:last-child) {
        padding-bottom: 24px;
      }

      .title {
        font-family: $headings-font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        color: $bb-green;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .body {
        font-family: $font-family-sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: $bb-green;
      }
    }
  }

  .accordion {
    margin: 16px 0;
  }
}

//Accordion
.accordion {
  .accordion-button {
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: $bb-green;

    .subtitle {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $bb-slate-light;
    }
  }

  .accordion-body {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $bb-green;
  }

}

.previous-page {
  text-decoration: none;
  font-weight: bold;
  color: $bb-green;
}