.ranking-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .nfs-ranking-number{
    font-size: 40px;
    color: #FB5C42;
    @media(min-width: 992px) {
      font-size: 60px;
    }
  }
}

.mobile-filters {
  display: block;
  @media(min-width: 992px) {
    display: none;
  }
  .mobile-filter-form {
    display: none;
  }
}

.desktop-filter-section{
  display: none;
  @media(min-width: 992px) {
    display: block;
  }
  .desktop-table-filter{
    display: flex;
    gap: 20px;
  }
}

.avg-wait-header{
  justify-content: space-between;
  display: flex;

  div{
    .small-store-number{
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 9px;
      color: $bb-slate-light;
    }
    .wait-time{
      color: #00C4B3 !important;
    }
  }
}