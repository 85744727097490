// FORM GENERIC
.input-help {
  font-size: $font-md;
  font-family: $tenso;
  color: $bb-slate-light;
}

input[type=text],
input[type=email],
input[type=password] {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}

input[type=password] {
  background-image: url("../images/eye.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center ;
}

input[type=search] {
  padding-left: 36px;
  background-image: url("../images/search.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 15px center ;
}

textarea.form-control {
  border-radius: 12px;
}

.form-switch .form-check-input {
  background-color: $bb-teal-dark;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba%28255, 255, 255, 1%29'/></svg>");
  height: 1.1rem;
  opacity: .5;

  &:disabled {
    background-color: $gray;
    border-color: $gray;
  }

  &:checked {
    opacity: 1;

    &:disabled {
      opacity: .5;
    }
  }
}

label {
  font-family: $tenso;
  font-weight: 500;
}

// changes checkbox appearance to radio appearance
.checkbox-radio
{
  border-radius: 50% !important;
  border: 1px solid $bb-teal-dark;
  &:checked
  {
    border-radius: 50%;
  }
  &:focus-visible
  {
    outline: auto;
  }
}

// CONTENT FORM
.content-form {
  padding: 16px 12px;

  .form-header {
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: $bb-green;
  }

  .submit-section {
    .btn {
      width: 100%;
      a {
        text-decoration: none;
        color: $white;
      }
    }
  }

  .form-instructions {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $bb-green;

    .header {
      margin-bottom: 5px;
      font-family: $headings-font-family;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
    }

    *:last-child {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 5px;
    }

    ol, ul {
      li {
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }

    ol {
      padding-left: 32px;
    }

    ul {
      padding-left: 16px;
      list-style: none;
    }
  }
}

//SHOP HOURS EDIT FORM
.hidden {
  display: none !important;
}
.shop-hours {
  .display-column {
    display: none;
  }
  .form-check {
    input, label {
      cursor: pointer;
    }
    label {
      line-height: 26px;
      color: $gray;
    }
  }
  .dropdowns {
    max-width: 300px;
    .dropdown {
      width: inherit;
      button, select {
        border-radius: 30px;
        border-color: #EBEBEB;
        font-family: $font-family-sans-serif;
        font-weight: 400;
        color: $gray;
        font-size: 12px;
        width: inherit;
        box-shadow: 0px 0px 4px 0px #f3f3f3;
        &::after {
          content: none;
        }
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-align: left;
      }
      &::after {
        position: absolute;
        right: 11px;
        top: 7px;
        content: url("/dist/images/down-caret.svg");
        pointer-events: none;
      }
    }
    p {
      color: $gray;
      margin-bottom: 0;
      line-height: 33px;
      padding: 0 12px;
      font-size: 12px;
    }
  }
  &.saved {
    .row:nth-child(even) {
      background: $bb-teal-lighter;
    }
    .edit-icon, .display-column {
      display:  block !important;
    }
    .save, .column-3 {
      display: none;
    }
    .dropdowns {
      display: none !important;
    }
    .column-2 {
      font-weight: 500 !important;
    }
  }
}
