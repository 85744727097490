//documents list 
.documents-list {
    .document {
        > * {
            cursor: pointer;
        }
        &:not(:last-child) {
            border-bottom: 1px solid #E5E7EB;
        }
        .background-square {
            min-width: 48px;
            height: 48px;
            &.blue {
                background-color: #bbe7e3;
            }
            &.yellow {
                background-color: #fee5bb;
            }
        }
        h4 {
            font-size: 14px;
            margin-bottom: 2px;
        }
        p {
            color: $bb-slate-light;
            font-size: 12px;
        }
        .max-character-text {
            p {
                overflow: hidden;
                max-width: 33ch;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        img {
            transform: rotate(-90deg);
            margin-left: auto;
        }
    }
}

//incident process chart
.process-chart {
    h4 {
        color: $bb-teal-medium;
    }
    ul li, p {
        font-size: 14px;
        color: $regular-text-color;
    }
    ul {
        padding: 35px;
    }
    .blue {
        color: rgb(0, 166, 251);
    }
    .pink {
        color: rgb(255, 72, 148);
    }
    .purple {
        color: rgb(180, 129, 234);
    }
}

//crisis cards
.crisis-cards {
    svg {
        cursor: pointer;
    }
    .card {
        background-image: url('/assets/images/example-document.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 145px;
        box-shadow: 0px 0px 4px 0px #f3f3f3;
        cursor: pointer;
        .card-footer {
            height: unset;
            padding: 12px 22px 12px 12px;
            background-color: rgba(162, 215, 206, .5);
            a {
                width: fit-content;
                margin-left: auto;
            }
        }
    }
}