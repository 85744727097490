
//support center accordion
.support-accordion {
    $accordion-border-width: 0 !important;
    .title-section {
        .faqs {
            color: $bb-teal;
            font-size: 12px;
            font-family: $tenso;
        }
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            font-family: $font-family-sans-serif;
        }
    }
    .search-bar .search-icon img {
        left: 14px;
        top: 9px;
    }
    .accordion-item {
        background-color: #F4FBFA;
        border-radius: 8px;
        .accordion-button {
            box-shadow: 0px 1px 0px #eeeeee;
            border-radius: 8px;
            svg {
                top: 8px;
                transition: transform 0.5s;
            }
            &:not(.collapsed) {
                box-shadow: none;
                svg  {
                    transform: rotate(180deg);
                    path {
                        fill: $bb-teal;
                    }
                }
            }
            &::after {
                content: none;
            }
        }
        p {
            font-size: 12px;
            color: $bb-slate-light;
            line-height: normal;
        }
    }
}

//council dropdown
.council-dropdown {
    button {
        background-color: #FAFAFA !important;
        border: none;
        border-radius: 50px;
        color: $bb-green-alt;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        padding: 10px;
        &::after {
            border: none;
        }
    }
    ul {
        font-size: 12px;
        padding-left: 8px;
        li {
            cursor: pointer;
            font-family: $tenso;
        }
    }
}

//council cards
.council-cards {
    .council-card {
        box-shadow: 0px 1px 20px #eeeeee;
        h3 {
            font-size: 14px;
        }
        svg {
            margin-left: 7px;
            cursor: pointer;
        }
        p {
            color: $bb-slate-light;
            font-family: $Inter;
            font-size: 10px;
        }
    }
}