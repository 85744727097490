$operations-h4-primary-font: Tenso, Helvetica, Arial, sans-serif;
$operations-h4-secondary-font: Inter, Helvetica, Arial, sans-serif;
$operations-h4-color: #0F5E53;
$operations-hr-color: #F2F2F2;
$stat-bgcolor-danger: #FDE1CD;
$stat-bgcolor-success: #CDFDC6;
$stat-bgcolor-average: rgba(27, 174, 160, .3);
$stat-bgcolor-total: #A2D7CE;
$card-form-label-color: #607D8B;
$card-form-label-font: Helvetica, Arial, sans-serif;


// helpers

.align-center
{
    text-align:center;
}

.text-bg-danger,
.text-bg-success,
.text-bg-average,
.text-bg-total
{  
    display:flex;
    justify-content: center;
    align-items: center;
    width: size(26);
    height: size(26);
}

.text-bg-danger
{  
    float:right;
    background-color: $stat-bgcolor-danger !important;
}
.text-bg-success
{  
    float:right;
    background-color: $stat-bgcolor-success !important;
}
.text-bg-average
{  
    float:right;
    background-color: $stat-bgcolor-average !important;
}
.text-bg-total
{
    background-color: $stat-bgcolor-total !important;
}

// required: border-bottom on card-header extends to shadow ends...
.card-hr-divider
{
    height: 1px;
}

.operations,
.operations .card-body
{
    width: 360px; //used only for mobile demo
    margin: 0 auto; //used only for mobile demo to center
    
    & .card-hr-divider
    {
        background: $operations-hr-color;
        margin: 8px;
    }

    & .card
    {
        height: auto !important;
    }

    & .card-body
    {
        padding-bottom: size(8);
    }

    & .user-info-wrapper
    {
        padding-bottom: 0 !important;

        & .col
        {
            display:flex;
            justify-content: space-between;
        }
        
    }

    & .user-info-container
    {   
        text-align: center;
        width: 100%;
        
        & .user-image img
        {

            width: size(56);
            height: size(56);
        }
        & .user-name
        {
            display:inline-block;
            font: bold size(10) $operations-h4-secondary-font;
            text-align:center;
        }
    }

    & .stats-card
    {
        margin-bottom: size(10);

        h3
        {
            line-height: size(26); //match icon height
            margin:0;
        }

        
        & img 
        {
            width: 14px;
            height: 14px;
        }

        & .card-body
        {
            display:flex;
            justify-items: left;
            gap: 26px;
            padding-top: 0;
            padding-bottom: 0;
        
            & .stats-value-title,
            & .stats-value
            {
                font-family: $operations-h4-primary-font;
                

                & p
                {
                    padding: 0;
                    margin: 0;
                    text-align:center;
                }
            }

            & .stats-value-title
            {
                font-size: size(12);
                color: $operations-h4-color;
            }
            & .stats-value
            {
                font-size: size(20);
                font-weight: bold;
                color: $operations-h4-color;
            }
        }

        & .card-footer
        {
            background-color: transparent;
            padding-top: 0;
            padding-bottom: 0;

            & p
            {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: size(12);
                color: $operations-h4-color;

                & .text-success
                {
                    color: #00C991 !important;
                }
            }
        }

        
    }

    & .library
    {
        & h3
        {
            white-space: nowrap;
        }
        .carousel-cell
        {
            width: size(140);
            height: size(100);
            margin-right: size(16);
        }

        & .video
        {
            display: flex;
            width: size(140);
            height: size(100);
            border-radius: size(12);

            & .meta-container
            {
                display:flex;
                align-self: flex-end;
                padding: size(6) size(8);
                width: 100%;
                

                & .type,
                & .length
                {
                    
                    font-family: $operations-h4-primary-font;
                    font-size: size(8);
                    font-weight: normal;
                    border-radius: size(6);
                    white-space: nowrap;
                    padding: 2px 8px;
                    
                }
                & .type
                {
                    background-color: rgba(244, 251, 250, .9);
                    color: $bahama-teal;
                }
                & .length
                {
                    background-color: rgba(107, 114, 128, .8);
                    color: $bahama-white;
                    margin-left: auto;
                }
            }
        }
    }

    & .flash-cards
    {
        & .form-select
        {
            padding: 0 2.625em 0 0.875em
        }
        & .card-header-secondary
        {
            font: normal size(12) $operations-h4-primary-font;
        }
        & .card-body
        {
            width: 100%;
        }
        & p
        {
            font: italic size(12) $operations-h4-primary-font;
            color: $operations-h4-color;
        }
        & .flash-cards-body-icon
        {
            background: $operations-h4-color;
            width: size(48);
            height: size(48);
            padding: size(12);
        }
        & .form-check-label
        {
            font: size(12) normal $card-form-label-font;
            color: $card-form-label-color;
        }

    }

    & .upcoming-events
    {
        & .thumbnail
        {
            display: flex;
            flex-direction: column;
            width: size(164);
            height: size(164);
        }
        & .event-label
        {
            background-color: rgba(244, 251, 250, .9);
            color: $bahama-teal;
            text-decoration:none;
            align-self: center;
            flex-direction: flex-start;
            font-size: size(8);
            font-weight: normal;
            border-radius: size(6);
            white-space: nowrap;
            padding: size(2) size(8);
            margin-top: size(8);

        }

        
    }
    & .event-info
    {
        & .title
        {
            margin-top: size(10);
            font: bold size(14) $operations-h4-primary-font;
            color: $operations-h4-color;
        }
        & .date,
        & .text
        {
            margin-top: size(10);
            font: normal size(12) $card-form-label-font;
            color: $operations-h4-color;
        }

    }

}

#attendees
{
    thead th
    {
        
        background: $bahama-teal;
        color: $bahama-white;
        font-size: size(14);
    }
    thead th:first-child 
    {
        border-radius: 12px 0 0 0;
    }
    thead th:last-child 
    {
        border-radius: 0 12px 0 0;
    }

    tr
    {
        background: $bahama-white;
    }
    .alt-row
    {
        background: #F4FBFA;
    }
    
    thead th:first-child,
    tbody th:first-child
    {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 36px;
    }

    tbody tr,
    tbody td
    {
        font-family: $operations-h4-primary-font;
        font-size: size(14);
        white-space: nowrap;
    }
    tbody a
    {
        text-decoration: none;
        font-weight: normal;
    }

    .tag-purple,
    .tag-blue
    {
        border-radius: size(6);
        white-space: nowrap;
        padding: size(2) size(8);
    }
    .tag-purple
    {
        color: #B481EA;
        background: rgba(180, 129, 234,.2);
    }
    .tag-blue
    {
        color: #00A6FB;
        background: rgba(0, 166, 251,.2);
    }
    
}

.training-library {
    iframe {
        height: 450px;
        border-radius: 8px
    }
}