//@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

#primary-desktop-nav
{
    @include media-breakpoint-up(xl)
    {
        background: $white;
        border-top-right-radius: size(24);
        border-bottom-right-radius: size(24);
    }
}

.icebox
{
    
    @include media-breakpoint-up(md)
    { 
        display:block;
        width: 18px;
        height: 18px;
        background-repeat: none;
        background-position: center center;
        background-size: contain;
    }
    &.logo
    {
        @include media-breakpoint-up(md)
        {   
            background-image: url(/dist/images/nav-sidebar-stacked-logo.png);
            width: 55px;
            height: 85px;
            padding:0;
            margin:0;
            background-position: left top;
            background-repeat: no-repeat;
        }
        @include media-breakpoint-up(xl)
        {   
            background-image: url(/dist/images/mobile-icebox-logo.svg);
            width: 126px;
            height: 53px;
        }

        
    }
    
    &.dashboard{background-image: url(/dist/images/icon-dashboard.svg);}
    &.my-shop{background-image: url(/dist/images/icon-my-shop.svg);}
    &.people{background-image: url(/dist/images/icon-operations.svg);}
    &.news{background-image: url(/dist/images/icon-news.svg);}
    &.marketing{background-image: url(/dist/images/icon-marketing.svg);}
    &.financial{background-image: url(/dist/images/icon-financial.svg);}
    &.operations{background-image: url(/dist/images/icon-operations.svg);}
    &.brand-management{background-image: url(/dist/images/icon-brand-management.svg);}
    &.bucks-on-demand{background-image: url(/dist/images/icon-bucks-on-demand.svg);}
    &.resources{background-image: url(/dist/images/icon-resources.svg);}
    &.maps{background-image: url(/dist/images/icon-maps.svg);}
    &.franchising{background-image: url(/dist/images/icon-franchising.svg);}
    &.notifications{background-image: url(/dist/images/icon-notifications.svg);}
    &.help{background-image: url(/dist/images/icon-help.svg);}
    &.desktop-nav-toggle{background-image: url(/dist/images/icon-desktop-nav-toggle.svg);}
    
}

#nav-list,
#nav-list li
{
    margin:0;
    padding:0;
    list-style-type: none;
}

.nav-item-name
{
    @include media-breakpoint-up(md)
    {
        display:none;
    }
    @include media-breakpoint-up(xl)
    {
        display:block;
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 500;
        font-size: size(16);
        line-height: 12px;
        color: $bb-green;
    }
}
#nav-list a
{
    
    @include media-breakpoint-up(md)
    {
        display: flex;
        width: size(56);
        height: size(56);

        justify-content: center;
        align-items: center;

        &:hover,
        &.active
        {
            
            height: size(56);
            background-color: rgba(0, 196, 179,.3);
            border-right: 1px solid rgb(0,196,179);

            & .rounded-circle
            {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                width: 46px;
                height: 46px;
                background:rgb(0, 196, 179);
            }

            & .dashboard{background-image: url(/dist/images/icon-dashboard-active.svg);}
            & .my-shop{background-image: url(/dist/images/icon-my-shop-active.svg);}
            & .people{background-image: url(/dist/images/icon-operations-active.svg);}
            & .news{background-image: url(/dist/images/icon-news-active.svg);}
            & .marketing{background-image: url(/dist/images/icon-marketing-active.svg);}
            & .financial{background-image: url(/dist/images/icon-financial-active.svg);}
            & .operations{background-image: url(/dist/images/icon-operations-active.svg);}
            & .brand-management{background-image: url(/dist/images/icon-brand-management-active.svg);}
            & .bucks-on-demand{background-image: url(/dist/images/icon-bucks-on-demand-active.svg);}
            & .resources{background-image: url(/dist/images/icon-resources-active.svg);}
            & .maps{background-image: url(/dist/images/icon-maps-active.svg);}
            & .franchising{background-image: url(/dist/images/icon-franchising-active.svg);}
            & .notifications{background-image: url(/dist/images/icon-notifications-active.svg);}
            & .help{background-image: url(/dist/images/icon-help-active.svg);}
            & .desktop-nav-toggle{background-image: url(/dist/images/icon-desktop-nav-toggle-active.svg);}
        }
    }
    @include media-breakpoint-up(xl)
    {
        display: flex;
        width: 100%;
        height: size(56);
        justify-content: flex-start;
        align-items: center;
        padding-right: size(16);

        &:link,
        &:visited,
        &:hover,
        &.active
        {
            height: size(56);
            text-decoration: none;

            & .rounded-circle
            {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 46px;
                height: 46px;
                background: none;
            }

            &.dashboard{background-image: url(/dist/images/icon-dashboard.svg);}
            &.my-shop{background-image: url(/dist/images/icon-my-shop.svg);}
            &.people{background-image: url(/dist/images/icon-operations.svg);}
            &.news{background-image: url(/dist/images/icon-news.svg);}
            &.marketing{background-image: url(/dist/images/icon-marketing.svg);}
            &.financial{background-image: url(/dist/images/icon-financial.svg);}
            &.operations{background-image: url(/dist/images/icon-operations.svg);}
            &.brand-management{background-image: url(/dist/images/icon-brand-management.svg);}
            &.bucks-on-demand{background-image: url(/dist/images/icon-bucks-on-demand.svg);}
            &.resources{background-image: url(/dist/images/icon-resources.svg);}
            &.maps{background-image: url(/dist/images/icon-maps.svg);}
            &.franchising{background-image: url(/dist/images/icon-franchising.svg);}
            &.notifications{background-image: url(/dist/images/icon-notifications.svg);}
            &.help{background-image: url(/dist/images/icon-help.svg);}
            &.desktop-nav-toggle{background-image: url(/dist/images/icon-desktop-nav-toggle.svg);}
        }
        &:hover,
        &.active
        {
            height: size(56);
            border-right: 1px solid rgb(0,196,179);
            text-decoration: none;
        }

        
    }
}
@include media-breakpoint-up(md)
{
    .dropdown-toggle 
    { 
        outline: 0; 
    }

    .btn-toggle 
    {
        padding: .25rem .5rem;
        font-weight: 600;
        color: rgba(0, 0, 0, .65);
        background-color: transparent;
    }
    .btn-toggle:hover,
    .btn-toggle:focus 
    {
        color: rgba(0, 0, 0, .85);
        background-color: #d2f4ea;
    }

    .btn-toggle::before 
    {
        width: 1.25em;
        line-height: 0;
        content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
        transition: transform .35s ease;
        transform-origin: .5em 50%;
    }

    .btn-toggle[aria-expanded="true"] 
    {
        color: rgba(0, 0, 0, .85);
    }
    .btn-toggle[aria-expanded="true"]::before 
    {
        transform: rotate(90deg);
    }

    .btn-toggle-nav a 
    {
        padding: .1875rem .5rem;
        margin-top: .125rem;
        margin-left: 1.25rem;
    }
    .btn-toggle-nav a:hover,
    .btn-toggle-nav a:focus 
    {
        background-color: #d2f4ea;
    }

    .scrollarea 
    {
        overflow-y: auto;
    }

    .bd-placeholder-img 
    {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .bd-placeholder-img-lg 
    {
            font-size: 3.5rem;
    }

    .bi 
    {
    vertical-align: -.125em;
    fill: currentColor;
    }

    .nav-scroller 
    {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
    }

    .nav-scroller .nav 
    {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    }
}
@include media-breakpoint-up(xl)
{
    .dropdown-toggle 
    { 
        outline: 0; 
    }

    .btn-toggle 
    {
        padding: .25rem .5rem;
        font-weight: 600;
        color: rgba(0, 0, 0, .65);
        background-color: transparent;
    }
    .btn-toggle:hover,
    .btn-toggle:focus 
    {
        color: rgba(0, 0, 0, .85);
        background-color: #d2f4ea;
    }

    .btn-toggle::before 
    {
        width: 1.25em;
        line-height: 0;
        content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
        transition: transform .35s ease;
        transform-origin: .5em 50%;
    }

    .btn-toggle[aria-expanded="true"] 
    {
        color: rgba(0, 0, 0, .85);
    }
    .btn-toggle[aria-expanded="true"]::before 
    {
        transform: rotate(90deg);
    }
    .btn-toggle-nav
    {
        padding:0;
        margin:0;
    }
    #nav-list .btn-toggle-nav a 
    {
        margin:0;
        padding-left: size(62);
        color: $bb-green;
        line-height: size(40);
        font-size: size(12);
    }
    .btn-toggle-nav a:hover,
    .btn-toggle-nav a:focus 
    {
        background-color: #d2f4ea;
    }

    .scrollarea 
    {
        overflow-y: auto;
    }

    .bd-placeholder-img 
    {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .bd-placeholder-img-lg 
    {
            font-size: 3.5rem;
    }

    .bi 
    {
    vertical-align: -.125em;
    fill: currentColor;
    }

    .nav-scroller 
    {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
    }

    .nav-scroller .nav 
    {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    }
}