/*
**
** $grid-columns is set to 12 by default
** main design width: 1440px in figma
**
*/

/* === GLOBAL PAGE LAYOUT STRUCTURE === */
$grid-columns: 16; // set to 16 instead of 12; if you change it here, change the totals in the following make-col() to match

.container{max-width: 100%;}// breakpoint max-width uses fixed widths, this broke wider screen layouts.

#master-container
{
    @include make-container();
    overflow-x: hidden;
}

.master-row
{
    @include make-row();
}

#primary-mobile-nav
{
    // hide mobile nav container from medium and up screens
    @include media-breakpoint-up(md)
    { 
        @include visually-hidden;
    }

}

#primary-desktop-nav
{

    @include make-col-ready();
   
    @include media-breakpoint-down(md)
    {   
        @include visually-hidden; //hidden in mobile
    }
    @include media-breakpoint-up(md)
    {   
        @include make-col(1);
        
    }
    @include media-breakpoint-up(xl) //expanded mode
    {   
        @include make-col(3);
        max-width: 300px;
        padding-right:0;
    }
}

#main-content-wrapper
{

    @include make-col-ready();
    
    @include media-breakpoint-up(sm)
    {   
        @include make-col(16); // full width mobile mode
    }
    @include media-breakpoint-up(md)
    {   
        @include make-col(15); // nav icon mode
    }
    @include media-breakpoint-up(xl) //nav icon expanded mode
    {   
        @include make-col(13);
    }
    
    & main
    {
        @include make-col();      
    
    }

    & #sidebar
    {
       
        @include media-breakpoint-down(md)
        {   
            @include make-col(16); // full width mobile mode
        }
        @include media-breakpoint-up(md)
        {   
            @include make-col(5); // nav icon mode
        }
        @include media-breakpoint-up(xl)
        {   
            @include make-col(4); // lg screen adjust
        }
    }
    
}

footer
{
    @include make-col(16);
}

/* === GLOBAL PAGE main === */

main
{
    padding-top: size(40);
    margin: 0 size(10);
    
    & .blank
    {
        padding:0;
        margin-top: size(24);

        & .card
        {
            padding: size(16);
            border-radius: size(24);
        }
        & .card-header
        {
            padding:0;
        }
    }
    & .faux-card
    {
        background-color: $white;
        border-radius: size(24);
        padding: size(16);
        margin-top: size(24);
    }
    & .panel-container
    {
        padding: size(16);
        margin-top: size(24);
    }
    & .faux-card-header
    {
        @include make-col-ready();
        @include make-col(10);
    }
    & .faux-card-action
    {
        @include make-col-ready();
     
            @include make-col(6);
            text-align:right;   
    }
    & .faux-card-header h3,
    & .header-action,
    & label
    {
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 700;
        font-size: size(14);
        text-transform: capitalize;
        text-decoration: none;
        color: $bb-green;
    }
    & .card-hr-divider
    {
        background-color: #F2F2F2 !important;
        margin: size(4) 0;
    }
    & .head-row
    {
        margin-top: size(40);
    }

    & .db-header,
    & .db-search
    {
        
        @include make-col-ready();
        @include media-breakpoint-up(sm)
        {
            @include make-col(16);
            
        }
        @include media-breakpoint-up(md)
        {
            @include make-col(8);
            
        }
    }

    & .db-header
    {
        & h2
        {
            //font-family: Halau-Bold; // font won't load with webpack
            font-family: Tenso;
            font-weight: bold;
            font-size: size(24);
            color: $bb-green;
        }
    }

    & .db-search
    {
        @include media-breakpoint-up(md)
        {
            display:inline-flex;
            justify-content: end;
            & input[type=search]
            {
                width: 300px;
            }
            
        }
        
    }
    & .sitewide-store-selector
    {
        @include media-breakpoint-down(md)
        {   
            & .form-select
            {
                width: auto;
                padding-top: 0;
                padding-bottom:0;
                padding-left:0;
                background-color:transparent;
                color: $primary !important;
                transition:none;
                border-radius: 0;
                font-family: 'Tenso';
                font-style: normal;
                font-weight: 700;
                font-size: size(14);
            }
            & label,
            & .form-select
            {
            float:left;
            }
        }
        @include media-breakpoint-up(md)
        {   
            & .form-select
            {
                width: auto;
                padding-top: 0;
                padding-bottom:0;
                background-color:transparent;
                color: $primary !important;
                transition:none;
                border-radius: 0;
                font-family: 'Tenso';
                font-style: normal;
                font-weight: 700;
                font-size: size(14);
            }
            & label,
            & .form-select
            {
            float:left;
            }
        }

        
    }
    & .full
    {
        
        width: auto;
    }
    & .value-container
    {   
        display: flex;
    }
    & .card .db-quick-links
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        & img
            {
                width: 24px;
                height: 24px;
                margin-right: size(12);
            }
        & a
        {
            display: block;
            width: 50%;
            font-family: Tenso;
            font-weight: bold;
            color: $bb-green;
            white-space: nowrap;
            text-decoration:none;
        }
    }
    & .card .db-brandmangement
    {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px; // smaller because icon circle is larger than other icons.
        & span
            {
                display: inline-flex;
                background: rgba(27, 174, 160, 0.3);
                border-radius: 100px;
                padding: 6px;
                width: 28px;
                height: 28px;
                margin-right: size(12);
            }
        & img
        {
            width: 16px;
            height: 16px;
        }
        & a
        {
            display: block;
            width: 50%;
            font-family: Tenso;
            font-weight: bold;
            color: $bb-green;
            white-space: nowrap;
            text-decoration:none;
        }
    }

    & .video
    {
        display: flex;
        width: 100%; // 276px - just a placeholder value since this width from figma needs to adjust
        height: calc(276px * .45); // height / width = .45
        border-radius: size(12);

        & .meta-container
        {
            display:flex;
            align-self: flex-end;
            padding: size(6) size(8);
            width: 100%;
            

            & .type,
            & .length
            {
                
                font-family: $operations-h4-primary-font;
                font-size: size(8);
                font-weight: normal;
                border-radius: size(6);
                white-space: nowrap;
                padding: 2px 8px;
                
            }
            & .type
            {
                background-color: rgba(244, 251, 250, .9);
                color: $bahama-teal;
            }
            & .length
            {
                background-color: rgba(107, 114, 128, .8);
                color: $bahama-white;
                margin-left: auto;
            }
        }
    }

    & .db-news
    {

        @include media-breakpoint-down(md)
        { 
            display:block;
        }

        & .db-news-item
        {

            @include media-breakpoint-down(md)
            { 
                margin-bottom: size(16);
            }

            & .thumbnail
            {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: calc(214px * .92)
            }

            
        }
        & .db-news-item-info
        {
            & .title
            {
                margin-top: size(10);
                font: bold size(14) $operations-h4-primary-font;
                color: $operations-h4-color;
            }
            & .date,
            & .text
            {
                margin-top: size(10);
                font: normal size(12) $card-form-label-font;
                color: $operations-h4-color;
            }

        }
    }
}

#sidebar
{
    background: $white;

    @include media-breakpoint-up(lg)
    {
        border-top-left-radius: size(24);  
        border-bottom-left-radius: size(24);    
    }
    & h2
    {
        font-family: Tenso;
        font-weight: bold;
        font-size: size(20);
        color: $bb-green;
    }
    & h3
    {
        font-family: Tenso;
        font-weight: bold;
        font-size: size(16);
        color: $bb-green;
    }

    & .sidebar-db-header
    {
        padding-left: size(10);
    }
    .form-check-label
    {
        font-family: Helvetica;
        font-size: size(14);
    }
    .todo-checked // for now this highlighted a completed todo item. not previously implemented and needs to be applied via js.
    {
        background: $bb-teal-light;

        & span
        {
            text-decoration:line-through;
        }
    }
}

.development,
.store-change
{
    .text-bg-danger
    {
        float:right;
    }
    & .card-hr-divider
    {
        background: $primary;
        margin-bottom: size(8);
    }
    & .value-row
    {
            display: flex;
            flex-direction: row;
            gap: size(8);
    }

    & .card-text
    {
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 500;
        font-size: size(14);
        line-height: 150%;
        color: $bb-green;
    }
    & .card-value
    {
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 500;
        font-size: size(20);
        line-height: 150%;
    }

    & .half
    {
        border-radius: size(24);
        box-shadow: 0px 0px 15px rgba(215, 219, 224, 0.25);

        & .value-container
        {   
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px;
            gap: size(8);

            & .card-value
            {
                color: $primary;
            }
        }

    }

    & .full
    {
        width: 100%;
        border-radius: size(24);
        box-shadow: 0px 0px 15px rgba(215, 219, 224, 0.25);
        padding: 16px;

        & .value-container
        {   
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & .card-text
            {
                white-space: nowrap;
                width: 50%;
            }
            & .card-value
            {
                width: 50%;
                text-align:right;
                font-size:size(16);
                color: $bb-green;
            }
            & .special
            {
                color: $primary;
            }

        }
    }
}

.store-change.row
{
    @include media-breakpoint-down(md)
    {
            display:block;
    }
        
}

.development
{
    @include media-breakpoint-down(sm)
    {
        & .value-row
        {
                display: block;
        }
    }
}