$primary-nav-dark-teal: #0F5E53;

#primary-mob-nav
{
  #displayNav {
    position: absolute;
    background-color: $white;
    z-index: 9999;
    min-width: 300px;
  }

  .nav-icon
  {
    margin-right: 18px;
  }

  a
  {
    font-family: Tenso, Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
  .dropdown-toggle { outline: 0; }

  .main-menu-container
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-toggler
  {
    margin: 6px 16px 0 0;
    font-weight: bold;
    color: $primary-nav-dark-teal;
    background-color: transparent; 
  }

  .btn-toggle,
  .navbar-brand 
  {
    position: relative;
    width: 100%;
    padding: 0px 26px;
    font-weight: bold;
    color: $primary-nav-dark-teal;
    background-color: transparent; 
  }
  
  button.btn-toggle 
  {
    display: block;
    height: 60px;
  }

  .btn-toggle i
  {
    color: $primary-nav-dark-teal;
    margin-right: 18px;
    font-size: size(24);
  }

  .btn-toggle::after 
  {
    width: 1.25em;
    line-height: 0;
    content:url("data:image/svg+xml,%3Csvg id='a' xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cpath d='M14,0h0c7.73,0,14,6.27,14,14h0c0,7.73-6.27,14-14,14h0C6.27,28,0,21.73,0,14H0C0,6.27,6.27,0,14,0Z' fill='none'/%3E%3Cpath d='M11.5,19l5-5-5-5' fill='none' stroke='%230f5e53' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E"); 
    transition: transform .35s ease;
    transform-origin: .5em 50%;
    position:absolute;
    right: 10px;
  }

  .btn-toggle[aria-expanded="true"] 
  {
    color: $primary-nav-dark-teal;
    background-color:rgba($color: #D2F4EA, $alpha: .5);
    border-radius: 12px 12px 0 0;
  }
  
  .btn-toggle[aria-expanded="true"]::after 
  {
    transform: rotate(90deg);
  }
  
  div.collapse
  {
    background-color:rgba($color: #D2F4EA, $alpha: .5);
    border-radius: 0 0 12px 12px;
  }

  .btn-toggle-nav a 
  {
    display: block;
    font-family: Tenso, Arial, Helvetica, sans-serif;
    font-size: size(18);
    font-weight: bold;
    color: $primary-nav-dark-teal;
    width: 100%;
    height: 28px;
    padding-left: 70px;
    margin-top: size(8);
  }

  .scrollarea {
    overflow-y: auto;
  }
}