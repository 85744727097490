$table-primary-font: Tenso, Helvetica, Arial, sans-serif;
$muted-underline-color: #E6EDFF;
$regular-text-color: #36524F;

/*
    DEFINES OFFCANVAS WITHIN CONTAINER
*/
#data-table-container
{
    position: relative;
}
.offcanvas 
{
    position: absolute;
}

/*
    OFFCANVAS CONTAINER STYLES
*/
#offcanvas
{
    background: rgba(255,255,255,1);
    border-left: none;
    border-radius: 0 12px 0 0;
    //box-shadow: 0px 0px 15px rgba(0,0,0,.25);
    border: 1px solid $muted-underline-color;
    padding: 16px;
    
    .offcanvas-container
    {
        @include make-container();
    }
    .panel-content,
    .panel-actions
    {
        @include make-col-ready();
        padding: 16px 0 0 0;
        
    }
    .panel-content,
    .panel-tabcontent
    {
        @include make-col();
        
        .rounded-circle
        {
            margin-bottom: 8px;
        }

        & h2
        {
            font: 700 size(20) $table-primary-font;
            color: $bb-green;
        }
        & h3
        {
            font: 500 size(16) $table-primary-font;
            color: $bb-green;
            //margin-bottom: 8px;
        }
        & h4
        {
            font: 500 size(14) $table-primary-font;
            color: $bb-teal;
        }

    }
    .panel-tabcontent
    {

        padding:0;
        border-bottom: 1px solid $muted-underline-color;

        & h3
        {
            margin-top: 16px;
            margin-bottom: 16px;
            font-weight: bold;

            & span
            {
                font-weight:normal;
                margin-left: 16px;
            }
        }
        .tab-col
        {
            @include make-col-ready;
            @include make-col(8);

            & h4
            {
                color: $bb-green;
                line-height: 150%;
                font: 500 size(14) Helvetica, Arial, sans-serif;
                white-space: nowrap;
            }

            & p
            {
                font: normal size(14) Helvetica, Arial, sans-serif;
                line-height: 150%;
                color: $regular-text-color;
            }
        }
        
    }

    .panel-actions
    {
        text-align:right;
        @include make-col(8);
    }

    .hr-divider
    {
        @include make-col(16);
        height: 1px;
        background: $muted-underline-color;
    }
    .profile-meta
    {
        text-align:center;
    }
    tbody tr,
    tbody td
    {
        font-family: $table-primary-font;
        font-size: size(14);
        white-space: nowrap;
    }
    tbody td
    {
        line-height: 1.5rem;
    }
    & .day
    {
        color: $bb-green;
        line-height: 150%;
        font: 500 size(14) Helvetica, Arial, sans-serif;
        white-space: nowrap;
    }

    & .status,
    & .time 
    {
        font: normal size(14) Helvetica, Arial, sans-serif;
        line-height: 150%;
        color: $regular-text-color;
    }
    & .disabled
    {
    color:#9EA0A2;
    }
    & .menu-item-name,
    & .menu-item-cost
    {
        color: $bb-green;
        line-height: 150%;
        font: 700 size(16) Helvetica, Arial, sans-serif;
        white-space: nowrap;
    }

}

#off-canvas-nav-tab
{
    
    & .active,
    & .nav-link
    {
        white-space:nowrap;
        font-weight: 500;
        border:none;
    }

    & .active,
    & .nav-link:hover
    {
        color: $bb-green;
        border-bottom: 1px solid $bb-green;
    }
}



/*
    MAIN DATA TABLE STYLES
*/
.pagination
{
    --bs-pagination-border-width:0;
    --bs-pagination-border-radius:0;
    & a
    {
        color: $bb-green;
    }
}

.pagination-container
{
    @include media-breakpoint-down(md)
    { 
        & .table-filters,
        & .table-actions
        {
            text-align:center;
            
        }
        & .table-actions
        {
            nav
            {
                display:flex;
                justify-content:center;
            }
        }
    }
    @include media-breakpoint-up(md)
    { 
        
        & .table-actions
        {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.breadcrumbs 
{
    & .profile-nav-breadcrumbs .crumb
    {
        font-family: $table-primary-font;
        font-size: size(18);
    }
}

.page-title
{
    h2
    {
        font-weight: bold;
        font-size: size(24);
        color: $bb-green;
    }
}

.sortable-table
{
    
    thead th
    {
        
        background: $bahama-teal;
        color: $bahama-white;
        font-size: size(14);
    }
    thead th:first-child 
    {
        border-radius: 12px 0 0 0;
    }
    thead th:last-child 
    {
        border-radius: 0 12px 0 0;
    }

    tr
    {
        background: $bahama-white;
    }
    .alt-row
    {
        background: #F4FBFA;
    }
    
    thead th
    {
        line-height: size(54);
        
        &:first-child
        {
            padding-left: 36px;
        }
    }

    tbody th
    {
        line-height: size(72);
        font-weight: normal;
        &:first-child
        {
            padding-left: 36px;
        }
    }

    tbody tr,
    tbody td
    {
        font-family: $table-primary-font;
        font-size: size(14);
        white-space: nowrap;
    }
    tbody td
    {
        line-height: size(72);
    }
    tbody a
    {
        text-decoration: none;
        font-weight: normal;
    }

    .tag-purple,
    .tag-blue
    {
        border-radius: size(6);
        white-space: nowrap;
        padding: size(2) size(8);
    }
    .tag-purple
    {
        color: #B481EA;
        background: rgba(180, 129, 234,.2);
    }
    .tag-blue
    {
        color: #00A6FB;
        background: rgba(0, 166, 251,.2);
    }

}
.table-filters
{
    @include make-col-ready();
    @include media-breakpoint-down(md)
    {   
        @include make-col(16);
    }
    @include media-breakpoint-up(lg)
    {   
        @include make-col(12);
    }

    & .search-box
    {
        float:left;

        @include media-breakpoint-down(md)
        {   
            width: 100%;
            margin-bottom: size(10);
        }
        @include media-breakpoint-up(lg)
        {   
            min-width: 40%;
        }
    }

    & .store-filter,
    & .member-filter,
    & .role-filter,
    & .filter
    {
        float:left;

        @include media-breakpoint-down(md)
        {   
            width: 100%;

            & button
            {
                width: 100%;
                margin-bottom: size(10);
            }
        }
        @include media-breakpoint-up(lg)
        {
            width: auto;
            margin-left: size(16);
        }
    }
}

.table-actions
{
    text-align:right;

    @include make-col-ready();
    @include media-breakpoint-down(md)
    {   
        @include make-col(16);
        & button
        {
            width: 100%;
        }
    }
    @include media-breakpoint-up(lg)
    {   
        @include make-col(4);
    }
}

.panel
{
    padding: size(16);

    .panel-nav 
    {
        @include make-col-ready();
        @include make-col();

        width: 100%;
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid $muted-underline-color;

        & a 
        {
            font-family: $headings-font-family;
            font-style: normal;
            font-weight: 400;
            font-size: size(17);
            text-decoration:none;
            line-height: 150%;
            text-align: center;
            padding-bottom: size(7) !important;
            margin-right: size(16);
        }
        & .active 
        {
            font-weight: 700;
            padding-bottom: 7px !important;
            margin-bottom: -1px;
            border-bottom: 1px solid $bb-teal-medium;
            color: $bb-green;
        }
    }
}

