$shop-general-inquiry: $bahama-teal;
$shop-good-experience: $bahama-green;
$shop-bad-experience: $bahama-orange;
$shop-average-response: $bahama-white;
$shop-average-border: #A2D7CE;
$shop-average-card-footer-bg: rgba($color: #D2F4EA, $alpha: .36);
$shop-dark-teal: #0F5E53;
$shop-score-normal-bg: #DEE5E9;
$shop-score-normal: #52727f;
$shop-score-negative-bg: #ffeeec;
$shop-score-negative: #f05c4c;
$shop-award-card-bg: #F2F9F9;
$shop-h4: Inter;
$shop-evaluation-date: Helvetica, sans-serif;
$card-height: 100%;

.small-cards
{
    .inner-card
    {
        background-color: $shop-average-response;
        border: 1px solid $shop-average-border;
        height: 100px;
        margin-bottom: 20px;
    }
    .card-footer
    {

        position:absolute;
        width: 100%;
        bottom: 0;
        background-color: $shop-average-card-footer-bg;
        vertical-align: middle !important;
        height: 34px;
        padding:0;
        backdrop-filter: blur(1px);
    }
    .text-date
    {
        font-family: $shop-evaluation-date;
        font-size: size(10);
        font-weight: normal;
        color: $shop-dark-teal;
        padding:5px 0 0 10px;
        line-height: 1.25em;

    }
    a.shop-vellip
    {
        display:block;
        margin-right: 4px;
    }
    a.shop-vellip img
    {
        width: 16px;
        height: 30px;
        float:right;
    }
    .score-container
    {
        display:block;
        position: absolute;
        right: 7px;
        top: 7px;
        font-family: $shop-evaluation-date;
        font-size: size(10);
        font-weight: normal;
        color: $shop-score-normal;
        height: 22px;
        line-height: 20px;
        border-radius: 7px;
        white-space: nowrap;
        border: 1px solid $shop-score-normal;
        background-color: $shop-score-normal-bg;
        padding-left: 4px;
        padding-right: 4px;

        &.negative
        {
            color: $shop-score-negative;
            border: 1px solid $shop-score-negative;
            background-color: $shop-score-negative-bg;
        }

        & img.trend-icon
        {
            width: 18px;
            height: 18px;
        }
    }

}

#message-in-a-bottle
{
    .general,
    .good,
    .bad,
    .average
    {
        padding: 16px;
        margin-bottom: 20px;
        height: 160px;
        h4
        {
            font-family: $shop-h4;
            font-size: size(14);
            font-weight: bold;
            color: $bahama-white;
        }
    }
    .general
    {
        background-color: $shop-general-inquiry;
    }
    .good
    {
        background-color: $shop-good-experience;
    }
    .bad
    {
        background-color: $shop-bad-experience;
    }
    .average
    {
        background-color: $shop-average-response;
        border: 1px solid $shop-average-border;
        h4
        {
            color: $bahama-black;
            text-align: center;
        }
        .value-large
        {
            display: inline-flex;
            align-items:center;
            justify-content: center;
            color: $bahama-teal !important;
            & span
            {
                font-size: 14px;
                font-weight: normal;
                color: $bahama-black;
                margin-left: 10px;
            }
        }

    }
    .value-container-wrapper
    {
        display:flex;
        justify-content: space-around;
        align-items: center;
    }
    .value-container
    {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;

        & .value-large,
        & .value-indicator
        {
            color: $bahama-white;

        }

        & .value-large
        {
            font-size: size(24);
            font-weight: bold;
        }
        & .value-indicator
        {
            font-size: size(10);
            font-weight: normal;
        }
    }

    .divider-container
    {
        flex:1;
        display: flex;
        align-items: center;
        justify-content: center;

        & .septum
        {
            width: 1px;
            height: 24px;
            background-color: $bahama-white;
        }
    }

    .value-container-button
    {
        padding-top: 14px;
        .btn-shop
        {
        width: 100%;
        color: $bahama-teal;
        background-color: $bahama-white;
        border-color: $bahama-teal;

            &:disabled {
                color: $button-disabled;
                background-color: $button-disabled;
                border-color: $button-disabled;
            }
        }

        & img
        {
            width: 16px;
            margin-bottom: 2px;
        }

    }
}
#shop-awards
{
    .award
    {
        background-color: $shop-award-card-bg;
        padding: 10px;
        min-height: 100px;


        p
        {
            padding:0;
            margin:0;
        }

        .award-year
        {
            font-size: size(10);
            font-family:$shop-evaluation-date;
            color: $bahama-teal;
        }
        .award-title
        {
            color: $bahama-teal;
        }
        .award-comments
        {
            font-size: size(10);
            font-family:$shop-evaluation-date;

            & a
            {
                color: $bahama-teal;
                font-weight: normal;
                text-decoration:none;
            }
        }
    }

}


//shop playbook monthly calendar
.monthly-calendar {
    .calendar-button {
        width: fit-content;
    }
    .month {
        max-width: 500px;
        padding: 1.5rem 0.7rem 1.75rem;
        h3 {
            font-size: 20px;
            font-family: $tenso;
            color: $bb-green;
            font-weight: bold;
            margin-bottom: 0;
        }
    }
    .days {
        grid-template-columns: repeat(7, auto);
        width: fit-content;
        column-gap: 6px;
        p {
            color: #B3B3B3;
            font-family: 'VVDS_Halau', $tenso;
            text-align: center;
            margin: 0;
            padding: 9px 12px;
            &:nth-child(-n+7) {
                color: $bb-green;
                font-family: $tenso;
            }
            &.active:not(:nth-child(-n+7)) {
                border-radius: 4px;
                background-color: $bb-teal;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                color: $white;
            }
        }
        .prev-month, .next-month {
            color: $bb-green;
        }
    }
}

.calendar-button {
    .dropdown-toggle {
        &::after {
            border: none;
        }
    }
    ul {
        font-size: 12px;
        padding-left: 8px;
        min-width: 111px;
        li {
            cursor: pointer;
            font-family: $tenso;
            color: $bb-green;
            width: fit-content;
            font-weight: bold;
        }
    }
    img {
        transform: rotate(90deg);
    }
}

//shop playbook weekly calendar 
.weekly-calendar {
    .calendar-header {
        margin-bottom: 1.75rem;
        h3 {
            font-size: 14px;
            font-family: $tenso;
            color: $bb-green;
            font-weight: bold;
            margin-bottom: 0;
        }
        svg:last-child {
            margin-left: 15px;
        }
    }
    .calendar-days {
        .day-card {
            height: 64px;
            width: 40px;
            border: 1px solid $bb-teal;
            cursor: pointer;
            .number, .day {
                color: $bb-green;
            }
            .number {
                font-size: 16px;
                font-family: 'VVDS_Halau', $tenso;
            }
            .day {
                font-family: $tenso;
                font-size: 12px;
            }
            &.active {
                background-color: $bb-teal;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 15%);
                p {
                    color: $white;
                }
            }
        }
    }
}

//shop game plan cards
.game-plan-cards {
    .box {
        width: 160px;
    }
    .inner-card {
        background: url(/dist/images/my-shop/game-plan-card.svg) no-repeat;
    }
    
}

//shop message in a bottle inquiries
.mib {
    .collapsible-table:not(:last-child) {
        margin-bottom: 2rem;
    }
    .good-experience .table-section .header {
        background-color: $bahama-green;
    }
    .bad-experience .table-section .header {
        background-color: #FB5C42;
    }
    .inquiry-footer {
        p {
            font-family: 'tenso';
            color: $bb-teal;
        }
    }
}

//shop applicant info
.back {
    cursor: pointer;
    p {
        color: $bb-green;
        font-family: $tenso;
        margin: 0;
        font-size: 14px;
        margin-left: 10px;
    }
}
//styles multiple information cards on different pages
.information-card {
    .info-wrapper {
        border-radius: 8px;
        margin-bottom: 1rem;
        box-shadow: 0px 1px 0px #f2f1f1;
        padding: 1rem 0;
        
        .header {
            font-size: 12px;
            font-family: $font-family-sans-serif;
            color: $bb-slate-light;
        }
        .info {
            font-size: 14px;
            font-family: $tenso;
            color: $bb-green;
        }
        &:last-child {
            margin-bottom: 0;
            box-shadow: none;
        }
    }
    &.with-form .info-wrapper:last-child {
        margin-bottom: 1.25rem;
        box-shadow: 0px 1px 0px #f2f1f1;
    }
    input, select, textarea {
        padding: 8px 13px;
        color: $bb-green-alt;
        cursor: pointer;
        &::placeholder {
            color: $bb-green-alt;
        }
    }
    .select-field {
        position: relative;
        select {
            background-image: none;
        }
        &::after {
            content: url('/dist/images/icon-chevron-down-dark-green.svg');
            position: absolute;
            right: 15px;
            top: 32px;
            pointer-events: none;
        }
    }
    .date-field {
        position: relative;
        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            opacity: .02;
        }
        svg {
            position: absolute;
            right: 12px;
            top: 36px;
            pointer-events: none;
        }
    }
    textarea {
        min-height: 200px;
        &::placeholder {
            color: $gray;
        }
    }
    &.no-form .info-wrapper:last-child {
        box-shadow: none;
        margin-bottom: 0;
    }
}

//shop applications 
.applications {
    .collapsible-table {
        .table-section {
            .heading, .column {
                width: 32%;
            }
            .rows {
                .row {
                    .column-1 {
                        color: $bb-teal-medium;
                        font-family: $tenso;
                    }
                    .column-2 {
                        color: $bb-slate-light;
                        p {
                            margin: 0;
                            font-family: $font-family-sans-serif;
                        }
                        .answer {
                            margin: 2px 0 7px;
                            color: #36524F;
                        }
                    }
                    .column-3 {
                        display: flex;
                        justify-content: space-between;
                        align-self: flex-start;
                        svg {
                            max-width: 7px;
                            align-self: center;
                        }
                    }
                }
            }
        }
    }
}

//shop breadcrumb menu
.shop-dropdown {
    width: 101%;
    left: -1px;
    .accordion {
        .accordion-item {
            border-radius: 0;
            border: 0;
        }
        .accordion-header {
            background-color: $bb-teal-medium;
            .accordion-button {
                background-color: $bb-teal-medium;
                color: $white;
                &::after {
                    background-image: none;
                }
            }
        }
        .accordion-body {
            .breadcrumbs-btn {
                border-bottom: 1px solid $accent;
                a {
                    // color: $bb-teal-medium;
                    text-decoration: none;
                    color: $bb-green;
                }
            }
        }
    }
}

//shop modal 
#shop-info-update {
    .modal-header, .modal-footer {
        border: none;
    }
    .modal-header {
        align-self: flex-end;
        svg {
            width: 20px;
            height: 25px;
        }
    }
    .modal-body {
        text-align: center;
        svg {
            margin-bottom: 13px;
        }
        p {
            font-size: 14px;
            margin: 0;
            color: $bb-green-alt;
            font-weight: 100;
        }
    }
    .modal-footer {
        align-self: center;
    }
}

//shop menu signage
.menu-signage {
    .heading {
        width: unset !important;
        .edit {
        cursor: pointer;
        }
    }
}

//shop menu accordion
.menu-accordion {
    .accordion-item {
      border-radius: $border-radius;
      border: 0;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      button {
        border-radius: $border-radius;
        box-shadow: 0px 2px 2px 0px #f2f2f2;
        &:not(.collapsed) {
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          &::after {
            background-image: url("/dist/images/teal-accordion-caret.svg") !important;
          }
        }
        &::after {
          background-image: url("/dist/images/accordion-caret.svg") !important;
          width: 12px;
          height: 9px;
        }
      }
      .accordion-body {
        padding: 1.25rem 0 0;
        .header {
          padding: 9px 16px;
        }
      }
    }
}

//shop bbfc search bar 
.search-bar {
    .search-icon img {
        position: absolute;
        left: 25px;
        top: 25px;
    }
    input {
        background-image: none;
        padding: 8px 12px 8px 33px;
        font-weight: 100;
    }
    button {
        white-space: nowrap;
        align-self: center;
        margin-left: 7px;
        svg {
            margin-left: 3px;
        }
    }
}

//shop bbfc note section
.bbfc-note {
    .edit, .trash {
        cursor: pointer;
    }
    .note-info {
        span {
            font-weight: 600;
            font-family: $tenso;
        }
        p {
            color: $bb-green-alt;
        }
    }
    p {
        font-size: 14px;
        color: $bb-slate-light;
        margin-bottom: .5rem;
    }
    .images {
        max-width: 375px;
        img {
            border-radius: $border-radius;
            max-width: 100px;
            max-height: 70px;
            &:not(:last-child) {
                margin-right: 7px;
            }
        }
    }
}
