.btn-primary {
  color: $white;

  &:hover,
  &:active {
    color: $white !important;
    background-color: $bb-teal-dark !important;
  }

  &:disabled {
    color: $white;
    background-color: $button-disabled;
    border-color: $button-disabled;
  }
}

.btn-secondary,
.btn-outline-secondary {
  color: $bb-teal;
  background-color: white;
  border-color: $bb-teal;

  &:hover,
  &:active {
    color: $bb-teal-dark !important;
    background-color: transparent !important;
    border-color: $bb-teal-dark !important;
  }

  &:disabled {
    color: $button-disabled;
    background-color: transparent;
    border-color: $button-disabled;
  }
}

.btn-info {
  color: $bb-teal;
  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:active {
    color: $bb-teal-dark !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  &:disabled {
    color: $button-disabled;
    background-color: transparent;
    border-color: transparent;
  }
}

.desktop-filter-btn{
  font-size: 16px;
  padding: 8px 16px !important;
}

//all of the general manager design uses this slightly different button
.general-manager-button {
  font-size: 14px;
  font-family: $font-family-sans-serif;
  font-weight: bold;
  border-radius: 8px;
  padding: 12px 16px;
}