.breadcrumbs-menu {
    position: relative;
    overflow: hidden;
  
    .breadcrumbs-btn {
      display: block;
      width: 100%;
      font-family: 'Inter';
      font-style: normal;
      font-size: 12px;
      line-height: 150%;
  
      .btn-icon {
        transition: all $transition-duration;
        transform: rotate(180deg);
      }
  
      &.disabled {
        .btn-icon {
          transform: rotate(0);
        }
      }
    }
  
    .toggle-spacer {
      width: 100%;
      position: relative;
    }
  
    .toggle {
      position: absolute;
      font-weight: 700;
      color: white;
      background-color: $bb-green;
      z-index: 3;
  
      .btn-icon {
        margin-left: 17px;
      }
    }
  
    .entries-spacer {
      width: 100%;
      position: relative;
      transition: all $transition-duration;
    }
  
    .entries {
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 0px 0px 6px 6px;
      transition: all $transition-duration;
  
  
      &.disabled {
        transform: translateY(-200%);
      }
  
      .breadcrumbs-btn {
        font-weight: 500;
        color: $bb-green;
        background-color: white;
        border-bottom: 1px solid $accent;
      }
    }
  }
  
  .breadcrumb-menu-alt-spacer {
    position: relative;
    overflow-y: hidden;
    transition: all $transition-duration;
  
    .breadcrumb-menu-alt {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      padding: 12px 16px;
      align-items: center;
      border-top: 1px solid #E5E7EB;
      z-index: 5;
    
      .title {
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 130%;
        color: $bb-green;
      }
    
      .menu-toggle-btn {
        display: flex;
        align-items: center;
        font-family: 'Tenso';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 130%;
        color: $bb-teal-medium;
    
        .btn-icon {
          margin-left: 7px;
          transition: all $transition-duration;
        }
  
        &.disabled {
          .btn-icon{
            transform: rotate(180deg)
          }
        }
      }
    }
  
    .menu-spacer {
      transition: all $transition-duration;
  
      &.disabled {
        transform: translateY(-100%);
      }
    }
  
    .menu {
      position: absolute;
      bottom: 0;
      right: 16px;
      width: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: -10px 20px 44px rgba(38, 50, 56, 0.16);
      border-radius: 0 0 4px 4px;
      z-index: 4;
      transition: all $transition-duration;
  
      &.disabled {
        transform: translateY(-100%);
      }
  
      .entry {
        padding: 12px 5px;
        text-align: center;
        font-family: 'Inter';
        font-weight: 500;
        font-style: normal;
        font-size: 12px;
        line-height: 150%;
        color: $bb-green;
        border-bottom: 1px solid $accent;
      }
  
    }
  }