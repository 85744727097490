//two button search bar
.people-search {
    input {
        max-height: 30px;
    }
    button {
        font-size: 12px;
        font-family: 'Inter', $font-family-sans-serif;
        border-radius: 8px;
        padding: 8px 11px;
        align-self: auto;
        &.filter svg {
            width: 14px;
            margin-left: 4px;
        }
        &.add-new svg {
            margin-left: 0;
            margin-right: 12px;
        }
    }
}

//add new modal
.add-new {
    .modal-header {
        svg {
            width: 20px;
            height: 25px;
        }
    }
    .modal-body {
        h3 {
            font-family: 'tenso';
            font-size: 16px;
            color: $bb-green;
        }
        label {
            font-weight: 500;
            color: $bb-green-alt;
        }
        input {
            padding: 0.5rem 1rem;
            color: $bb-green-alt;
        }
        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
            -webkit-text-fill-color: $bb-green-alt !important;
            box-shadow: 0 0 0 30px #FAFAFA inset !important;
        }
    }
    .modal-footer {
        button {
            padding: 12px !important;
        }
    }
}

/** profiles section styles: 
- employee profiles
- store profiles
- corporate contact profiles
- national contacts profiles
**/
.profiles-section {
    margin: 20px 8px;
    .profile-list {
        .profile-wrapper {
            &:not(:first-child) {
                margin-top: 1.25rem;
            }
            &:last-child {
                border: none !important;
            }
            .profile-info {
                img {
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    object-fit: cover;
                }
                .content-wrapper {
                    grid-template-columns: repeat(2, auto);
                    h3 {
                        font-family: $font-family-sans-serif;
                        font-size: 14px;
                        grid-column-end: 4;
                        grid-column-start: 1;
                    }
                    .level {
                        p {
                            color: $bb-green;
                            font-size: 12px;
                            font-family: 'VVDS_Halau', $tenso;
                        }
                    }
                    .contact-info {
                        color: #607D8B;
                        font-size: 12px;
                        font-family: $font-family-sans-serif;
                        grid-column-end: 4;
                        grid-column-start: 1;
                        span {
                            font-size: 12px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
                
            }
        }
        button {
            margin-bottom: 1.25rem;
        }
    }
    .pagination-wrapper {
        width: fit-content;
        p {
            font-size: 14px;
        }
        nav {
            width: fit-content;
            border-radius: 50%;
            .page-item {
                &:first-child .page-link, &:last-child .page-link {
                    padding-right: 15px;
                    padding-left: 15px;
                }
                &:first-child {
                    img {
                        transform: rotate(90deg);
                        width: 12px;
                    }
                    .page-link {
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                    }
                } 
               
                &:last-child {
                    img {
                        transform: rotate(-90deg);
                        width: 12px;
                    }
                    .page-link {
                        border-top-right-radius: 50%;
                        border-bottom-right-radius: 50%;
                    }
                }
                
            }
        }
    }
}

//profile lists and profile cards styles
.profiles {
    .profile-image {
        color: $bb-teal;
        font-size: 12px;
        font-family: $tenso;
        margin-right: 15px;
        background-color: $bb-teal-lighter;
        min-width: 62px;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .button-wrapper {
        max-width: 400px;
    }
}

//profile cards
.profile-card {
    .profile-image {
        width: 90px;
        height: 90px;
        font-size: 16px;
        object-fit: cover;
    }
    .owner-name {
        color: $bb-teal;
    }
    button {
        font-size: 14px;
        a {
            font-family: $font-family-sans-serif;
        }
    }
    .level {
        p {
            font-family: 'VVDS_Halau', $tenso;
        }
    }
}

//my teams accordion menu
.my-teams-accordion {
    .accordion-item {
        margin-bottom: 2px !important;
        .accordion-button:not(.collapsed) {
            background-color: $bb-teal-lighter;
        }
        .form-check-input {
            width: 17px;
            height: 17px;
            border-color: $bb-teal-light;
        }
        label {
            font-size: 14px;
            color: #263238;
            font-family: $font-family-sans-serif;
            font-weight: 200;
            margin-left: 5px;
            top: 1px;
        }
    }
    button {
        font-family: 'Inter', $font-family-sans-serif;
        font-size: 12px;
    }
}

//franchise tabs
.franchise-tabs {
    .nav-tabs {
        button {
            font-family: $tenso;
            margin: 0 .5rem;
            &.active {
                font-weight: bold;
                border-bottom: 1px solid $bb-teal-medium;
                padding-bottom: 5px;
            }
        }
    }
    .tab-content {
        h3 {
            font-family: $font-family-sans-serif;
            font-size: 14px;
        }
    }
    .menu-accordion {
        .form-header {
            font-size: 14px;
        }
    }
}

//small search bar
.small-search-bar {
    img {
        top: 10px;
        left: 15px;
    }
    input {
        max-width: 135px;
        background-image: none;
    }
    button {
        align-self: center;
        padding: 8px 12px;
        height: fit-content;
    }
}

