

.card-container {
  padding: $gbl-padding-sm 0;

  .to-do-list {
    .card {
      overflow: hidden;
      box-shadow: 1px 1px 4px #D7DBE0 !important;
      margin-bottom: $gbl-padding;

      &:has(input:checked) {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08) !important;
      }

      label {
        display: grid;
        grid-template-columns: 26px 1fr;
        align-items: center;
        color: $bb-green-alt;
        background-color: $bb-teal-lighter;
        padding: $gbl-padding-sm;

        &:has(input:checked) {
          background-color: #A2D7CE !important;
          text-decoration: line-through;
        }

        input {
          border-color: $bb-teal-light;
          margin-top: 0;

          &:checked {
            border-color: $white;
          }
        }
      }
    }
  }
}

.card {
  box-shadow: 0 0 15px 0 #D7DBE0;

  &.no-shadow {
    box-shadow: none;
  }

  &.card-padding {
    padding: 16px;
  }

  &.static {
    background: url("../images/soft-static.png");
    background-size: 300px;
  }

  .card {
    box-shadow: 0 0 15px #D7DBE0;
  }



  .card-header {
    background-color: transparent;
    padding-bottom: 0;
    //display: grid;
    //grid-template-columns: 1fr 100px;

    * {
      font-size: size(14);
      font-weight: bold;
      color: $bb-green;
    }

    a {
      text-decoration: none;
      color: #36524FCC;

      &:hover {
        color: #36524F;
      }
    }
    .header-action {
      float: right;

      &.left-right a {
        display: inline-block;
        text-align: center;
        width: 24px;
        height: 24px;

        &:hover {
          svg path {
            stroke: $bb-teal;
          }
        }
      }
    }
  }

  &.data-bite {
    padding: 8px 4px;

    .alt-heading {
      display: flex;
      justify-content: space-between;

      .right {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.44px;

        .text {
          color: $bb-teal;
        }
      }
    }

    h4 {
      font-size: size(12);
      font-weight: bold;
      color: $bb-slate;
    }

    h5 {
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 9px;
      color: $bb-slate-light;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  &.teal-card
  {
    color: $bahama-white;
    background-color: $bb-teal;

    & .btn
    {
      width: 100% !important;
      color: $bb-teal;
      border-color: $bb-teal;

    }
  }
  &.green-card
  {
    color: $bahama-white;
    background-color: $bahama-green;
    & .btn
    {
      width: 100% !important;
      color: $bahama-green;
      border-color: $bahama-green;
    }
  }
  &.purple-card
  {
    color: $bahama-white;
    background-color: $purple;
    & .btn
    {
      width: 100% !important;
      color: $purple;
      border-color: $purple;
      fill: $purple;
      stroke: $purple;
    }
  }
  &.blue-card
  {
    color: $bahama-white;
    background-color: $darker-blue;
    & .btn
    {
      width: 100% !important;
      color: $darker-blue;
      border-color: $darker-blue;
    }
  }
  &.red-card
  {
    color: $bahama-white;
    background-color: $bahama-orange;
    & .btn
    {
      width: 100% !important;
      color: $bahama-orange;
      border-color: $bahama-orange;
    }
  }
  &.light-teal-card
  {
    color: $bb-teal-medium;
    background-color: $bb-teal-lighter;
    & .btn
    {
      width: 100% !important;
      color: $bb-teal-lighter;
      border-color: $bb-teal-lighter;
    }
  }
  &.white-card
  {
    color: $dark-gray;
    border: 1px solid $primary;
    & .request-total
    {
      color: $primary;
    }

    & .btn
    {
      width: 100% !important;
      color: $bb-teal;
      border-color: $bb-teal;

    }
  }

  .requests
  {
    & h4
    {
      font-family: $inter;
      font-size: size(14);
    }
    & .card
    {
      padding: 16px;
      height: size(131);
    }
    & .request-container-wrapper
    {
      display:flex;
      justify-content: space-around;
      align-items: center;
      height: size(36);
    }
    & .request-container
    {
      flex:1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      & .request-total
      {
        font-size: size(32);
        margin-right: size(4);
      }
      & .request-text
      {
        font-size: size(15);
        white-space: nowrap;
      }

    }
    & .button-container
    {
      padding-top: size(8);
      width: 100%;

      & .btn
      {
        width: 100% !important;
        background-color: $bahama-white;
        &:disabled {
          color: $button-disabled;
          background-color: $button-disabled;
          border-color: $button-disabled;
        }
      }

      & img
      {
        width: 16px;
        height: 16px;
        margin-bottom: 2px;
      }

    }
  }
}

.card-notification {
  display: inline-block;
  background: $bb-teal-light;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  margin-left: 10px;
  border-radius: 24px;
  font-size: 14px;
  top: -10px;
  position: relative;
}


//folder cards
.folder-cards {
  padding: 16px 7px;

  .folder-card {
    min-height: 145px;
    padding: 17px;
    border-radius: 12px;
    gap: 17px;
    h4 {
      font-size: 14px;
      line-height: 150%;
    }
    p {
      font-size: 10px;
      margin-bottom: 0;
      line-height: normal;
    }
  }
  .orange-card {
    background-color: #FDE1CD;
    color: #ea6e52;
  }
  .blue-card {
    background-color: #F2F9F9;
    h4 {
      color: $bb-teal-medium;
    }
    .documents {
      color: $bb-slate-light;
    }
  }
  .yellow-card {
      background-color: #FEE69B;
      color: #CF8213;
    }
}

//colored header cards
.pillar-card {
  border-radius: 24px;
  &.blue {
    border: 1px solid #00A6Fb;
    h4 {
      background-color: rgba(0, 166, 251, .2);
    }
  }
  &.purple {
    border: 1px solid rgba(180, 129, 234, 1);
    h4 {
      background-color: rgba(180, 129, 234, .2);
    }
  }
  &.pink {
    border: 1px solid rgba(255, 72, 148, 1);
    h4 {
      background-color: rgba(255, 72, 148, .2);
    }
  }
  h4 {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 12px;
  }
  p {
    padding: .5rem 1rem;
    margin: 0;
  }
  ul {
    padding: 1rem 1rem 1rem 3rem !important;
    margin-bottom: 0;
  }
}

//
//.card-answer{
//  visibility: hidden;
//}
//.carousel-cell:target > .flash-card > .card-answer{
//  visibility: visible !important;
//}