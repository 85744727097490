@font-face {
  font-family: "NATS";
  src: url("/fonts/NATS-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Halau";
  src: url("/fonts/Halau-Font/Halau-Regular.ttf");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Halau-Bold";
  src: url("/fonts/Halau-Bold.ttf");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: tenso;
  font-weight: 400;
  font-style: normal;
}


$nats: "NATS", sans-serif;
$halau: "Halau", serif;
$halauB: "Halau-Bold", serif;
$tenso: tenso, sans-serif;
