$calendar-tan: #feeae0;
$calendar-red: #ee1d23;
$calendar-cyan: #72d0c7;
$calendar-green: #6ec72d;
$calendar-purple: #943aa2;
$calendar-salmon: #f57160;
$calendar-dark-gray: #231f20;
$calendar-accent: #989898;

$event-nav-height: 20vh;

$ui-shadow: -7px 11px 77px -9px rgba(0,0,0,0.82);

.calendar-page {
  overflow-x: hidden;
  background: white;

  main {
    padding-top: 0;
  }
  
  .calendar-layout {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: $event-nav-height;
  
    .sidebar {
      width: 20%;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      .key {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @keyframes shrink {
          from {height: 100%;}
          to {height: 0%;}
        }
  
        @keyframes grow {
          from {height: 0%;}
          to {height: 100%;}
        }

        &.key-active {
          .key-heading {
            &:after {
              transform: rotate(0);
            }
          }

          .items {
            height: 100%;
            transform: translateY(0);
          }
        }

        .key-heading {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 30px;
          text-transform: uppercase;
          background-color: $calendar-tan;
          color: $calendar-dark-gray;
          font-size: 25px;
          font-weight: 900;
          font-family: 'Cali Sans', sans-serif;
          text-align: center;
          z-index: 2;

          @include media-breakpoint-down(md) {
            padding: 20px;

            font-size: 16px;
          }

          &:after {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            content: url(/dist/images/chevron-down-solid.svg);
            transform: rotate(-180deg);
            transition: all 0.3s;

            @include media-breakpoint-down(md) {
              width: 14px;
              height: 14px;
              margin-left: 5px;
            }
          }
        }

        .items {
          overflow: hidden;
          height: 0;
          padding: 0 20px 20px 20px;
          transition: all 0.3s;
          background-color: $calendar-tan;
          animation-name: shrink;
          animation-duration: 0.3s;
          transform: translateY(-200%);

          
          .item {
            width: 100%;
            display: flex;
            align-items: center;
            color: $calendar-dark-gray;
            font-family: 'Helvetica Nueue', sans-serif;
            font-size: 16px;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            .item-icon {
              min-width: 25px;
              width: 25px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              background-color: $calendar-dark-gray;
              border-radius: 100%;

              img {
                width: 12px;
              }
            }

            &.red {
              color: $calendar-red;

              .item-icon {
                background-color: $calendar-red;
              }
            }

            &.cyan {
              color: $calendar-cyan;

              .item-icon {
                background-color: $calendar-cyan;
              }
            }

            &.green {
              color: $calendar-green;

              .item-icon {
                background-color: $calendar-green;
              }
            }

            &.purple {
              color: $calendar-purple;

              .item-icon {
                background-color: $calendar-purple;
              }
            }

            &.salmon {
              color: $calendar-salmon;

              .item-icon {
                background-color: $calendar-salmon;
              }
            }
          }
        }
      }

      .month-select {
        margin-top: 20px;
        padding: 0 10px;
        transition: all 0.3s;

        @include media-breakpoint-down(lg) {
          margin-bottom: 30px;
        }

        &.sticky {
          @include media-breakpoint-down(md) {
            position: fixed;
            top: 0;
            width: 100%;
            margin-top: 10px;
            z-index: 10;

            input {
              box-shadow: $ui-shadow;
            }
          }
        }
      }

      .month-nav {
        margin-top: 30px;
        padding: 0 10px;
        width: 100%;

        @include media-breakpoint-down(lg) {
          display: none;
        }

        .adjacent-months {
          display: flex;
          flex-direction: column;
          align-items: center;

          .month-preview {
            width: 100%;
            border: 2px solid #231f20;
            max-width: 200px;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .month-name {
              width: 100%;
              padding: 3px;
              text-align: center;
              font-size: 20px;
              font-weight: 900;
              color: #231f20;
            }

            .week-header {
              font-size: 10px;
              color: #929598;
              padding: 0 10px 5px 10px;
              border-bottom: 2px solid #929598;
              display: flex;
              justify-content: space-between;
            }

            .days-container {
              padding: 10px;
              font-size: 10px;
              line-height: 16px;
              color: #231f20;

              .week {
                display: flex;
                justify-content: space-between;

                .day {
                  width: calc(100% / 7);
                  &.inactive {
                    color: #a4a7a9;
                  }
                }
              }
            }
          }
        }
      }
    }
    
    .calendar {
      width: 80%;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      .month-header {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        justify-content: space-between;
        padding: 0 0 10px 10px;
        color: $calendar-dark-gray;

        .month {
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: 40px;
          font-weight: 900;
          font-family: 'Cali Sans', sans-serif;
        }

        .year {
          padding: 20px 30px;
          background-color: $calendar-tan;
          font-size: 40px;
          font-weight: 200;
          font-family: 'Cali Sans Light', sans-serif;
        }
      }

      .calendar-grid {
        position: relative;
        width: 100%;
        padding: 0 10px 10px 10px;

        @include media-breakpoint-down(md) {
          padding: 0;
        }

        .week-heading {
          padding-bottom: 10px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          color: #757878;
          font-size: 12px;

          @include media-breakpoint-down(md) {
            display: none;
          }

          .day-heading {
            width: calc(100% / 7 - 10px);
            text-align: center;
            text-transform: uppercase;

            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        .days {
          max-width: 100%;
          width: 100%;
  
          .week {
            max-width: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
              flex-wrap: wrap;
            }
  
            &:not(:last-child) {
              margin-bottom: 5px;
            }
            
            .day {
              box-sizing: border-box;
              position: relative;
              width: calc((80vw - 20px) / 7);
              height: calc((80vw - 20px) / 7);
              cursor: pointer;

              @include media-breakpoint-down(lg) {
                width: calc((100vw - 20px) / 7);
                height: calc((100vw - 20px) / 7);
              }

              @include media-breakpoint-down(md) {
                width: 100%;
                height: unset;
                margin-bottom: 10px;

                &.vacant {
                  display: none;
                }
              }
  
              &:not(:last-child) {
                @include media-breakpoint-up(md) {
                  padding-right: 5px;
                }

                .events {
                  margin-right: 5px;
                }
              }

              &:first-child, &:last-child {
                .events {
                  @include media-breakpoint-up(md) {
                    background-color: #f0f0f0;
                  }
                }
              }

              &:hover {
                .events {
                  @include media-breakpoint-up(md) {
                    background-color: white;
                    outline: 2px solid $calendar-accent;
                  }
                  @include media-breakpoint-down(md) {
                    background-color: $calendar-tan;
                    outline: 2px solid $calendar-accent;
                  }
                }
              }

              .weekday {
                display: block;
                padding: 15px;
                background-color: white;
                color: #757878;
                font-size: 20px;

                @include media-breakpoint-up(md) {
                  display: none;
                }
              }

              .events {
                width: 100%;
                height: 100%;
                padding: 5px;
                background-color: #f9f9f9;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                outline: 2px solid white;
                transition: all 0.15s;

                @include media-breakpoint-down(md) {
                  height: unset;
                  padding: 15px;
                }

                .date {
                  width: 100%;
                  align-self: flex-start;
                }

                .events-list {
                  width: 100%;
                  padding: 5px;

                  .event {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    &:not(:last-child) {
                      margin-bottom: 5px;
                    }

                    &.marketing {
                      color: $calendar-red;

                      .icon {
                        background-color: $calendar-red;
                      }
                    }

                    &.operations {
                      color: $calendar-cyan;
                      
                      .icon {
                        background-color: $calendar-cyan;
                      }
                    }

                    &.financial {
                      color: $calendar-green;
                      
                      .icon {
                        background-color: $calendar-green;
                      }
                    }

                    &.maintenance {
                      color: $calendar-purple;
                      
                      .icon {
                        background-color: $calendar-purple;
                      }
                    }

                    &.communications {
                      color: $calendar-salmon;
                      
                      .icon {
                        background-color: $calendar-salmon;
                      }
                    }

                    .icon {
                      width: 24px;
                      min-width: 24px;
                      height: 24px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-right: 5px;
                      overflow: hidden;
                      border-radius: 100%;
                      background-color: $calendar-dark-gray;

                      @include media-breakpoint-down(xl) {
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                      }

                      @include media-breakpoint-down(md) {
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                      }

                      img {
                        width: 9px;

                        @include media-breakpoint-down(xl) {
                          width: 6px;
                        }

                        @include media-breakpoint-down(md) {
                          width: 9px;
                        }
                      }
                    }

                    .description {
                      padding-top: 5px;
                      font-size: 12px;
                      line-height: 14px;

                      ul, ol {
                        padding-left: 0;
                        margin: 0;
                      }

                      ol {
                        padding-left: 10px;
                      }

                      ul {
                        padding-left: 7px;
                        li {
                          &::marker {
                            content: '- ';
                          }
                        }
                      }
                    }
                  }
                }

                .truncation-notice {
                  display: none;
                  width: calc(100% - 10px);
                  margin: 0 auto;
                  padding: 10px 5px;
                  font-size: 12px;
                  line-height: 14px;
                  color: white;
                  background-color: $calendar-accent;
                  border-radius: 5px;
                  outline: 0;

                  @include media-breakpoint-down(xl) {
                    padding: 5px;
                    font-size: 10px;
                  }

                  @include media-breakpoint-down(md) {
                    padding: 10px 5px;
                    font-size: 12px;
                  }

                  &.show-events-active {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

    .event-scheduler {
      height: $event-nav-height;
      width: calc(80% - 10px);
      position: fixed;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $calendar-tan;
      z-index: 20;

      .event-scheduler-nav {
          padding: 0 20px;
          width: 100%;
        
        .event-scheduler-open-btn, .event-scheduler-close-btn {
          display: none;
          width: 100%;
          background-color: white;
          padding: 15px;
          border-radius: 15px;
          color: $calendar-dark-gray;
          border: 2px solid $calendar-dark-gray;
        }

        .event-scheduler-open-btn {
          box-shadow: $ui-shadow;

          @include media-breakpoint-down(md) {
            display: block;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        height: auto;
        padding: 30px;
        background-color: unset;

        .events-list {
          display: none !important;
        }

        &.event-scheduler-active {
          height: 100%;
          background-color: $calendar-tan;

          .event-scheduler-open-btn {
            display: none;
          }

          .event-scheduler-close-btn {
            display: inline;
          }

          .events-list {
            display: flex !important;
          }
        }
      }

      .events-list {
        width: 100%;
        padding: 20px;
        position: relative;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
          flex-direction: column;
        }

        .event {
          width: calc(100% / 6 - 10px);
          position: relative;

          @include media-breakpoint-down(md) {
            width: 100%;
            border-radius: 15px;

            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .event-editor {
      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-y: scroll;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 20;

      &.active {
        display: block;
      }

      &.update-state {
        .inner {
          .event-editor-add {
            display: none;
          }
  
          .event-editor-update, .event-editor-delete {
            display: inline;
          }
        }
      }

      button {
        background-color: white;
        padding: 15px;
        border-radius: 15px;
        color: $calendar-dark-gray;
      }

      .inner {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 1000px;
        padding: 10vh 20px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md) {
          padding: 15px;
        }

        .inner-nav{
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .event-editor-close {
            margin-bottom: 15px;
            color: white;
            background-color: $calendar-red;
          }
        }

        .event-time-options {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          padding: 10px;
          border-radius: 10px;
          background-color: white;

          .time-row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            .time-col {
              label {
                margin-right: 10px;
              }
              &:not(:last-child) {
                margin-right: 20px;
              }
            }
          }
        }

  
        .event-editor-add, .event-editor-update, .event-editor-delete {
          margin-top: 15px;
          color: white;
        }

        .event-editor-add, .event-editor-update {
          background-color: $calendar-green;
        }

        .event-editor-update, .event-editor-delete {
          display: none;
        }

        .event-editor-update {
          margin-right: 15px;
        }

        .event-editor-delete {
          background-color: $calendar-red;
        }
      }
    }

    .day-summary {
      height: 0;
      overflow: hidden;
      width: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 30px;
      background-color: white;
      transform: translateY(100vh);
      transition: all 0.3s;
      z-index: 20;


      @include media-breakpoint-down(md) {
        padding: 0 15px;
      }

      &.day-summary-active {
        height: auto;
        overflow-y: scroll;
        transform: translateY(0);
      }

      .summary-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;

        @include media-breakpoint-down(md) {
          padding: 15px 0;
        }

        h2 {
          margin-bottom: 0;
          margin-right: 20px;
          text-transform: uppercase;
          font-size: 40px;
          font-weight: 900;
          font-family: 'Cali Sans', sans-serif;
          color: $calendar-dark-gray;

          @include media-breakpoint-down(md) {
            font-size: 16px;
          }
        }

        .summary-close-btn {
          padding: 10px 15px;
          background-color: white;
          color: $calendar-dark-gray;
          font-weight: 900;
          border-radius: 15px;
          border: 2px solid $calendar-dark-gray;
        }
      }

      .events {
        .accordion {
          .accordion-item {
            .accordion-header {
              .accordion-button {
                display: flex;
                flex-wrap: wrap;

                .header-info {
                  width: 100%;
                  margin-bottom: 20px;

                  .event-type {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    &.marketing {
                      .event-type-icon {
                        background-color: $calendar-red;
                      }
                    }

                    &.operations {
                      .event-type-icon {
                        background-color: $calendar-cyan;
                      }
                    }

                    &.financial {
                      .event-type-icon {
                        background-color: $calendar-green;
                      }
                    }

                    &.maintenance {
                      .event-type-icon {
                        background-color: $calendar-purple;
                      }
                    }

                    &.communications {
                      .event-type-icon {
                        background-color: $calendar-salmon;
                      }
                    }

                    .event-type-icon {
                      position: relative;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 40px;
                      height: 40px;
                      margin-right: 10px;
                      border-radius: 100%;
                      background-color: $calendar-dark-gray;

                      img {
                        width: 20px;
                      }
                    }
                  }

                  .event-time {
                    margin-left: 50px;
                    margin-bottom: 10px;
                  }

                  .event-summary {
                    margin-left: 50px;
                  }
                }

                .event-controls {
                  margin-left: 50px;

                  .event-edit-button {
                    padding: 10px 15px;
                    background-color: white;
                    color: $calendar-dark-gray;
                    border-radius: 15px;
                    border: 2px solid $calendar-dark-gray;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

    .event-editor, .day-summary {
      button {
        @include media-breakpoint-down(md) {
          font-size: 10px;
          padding: 8px;
          font-weight: 700;
        }
      }
    }

  }
}

.active-event-card {
  position: absolute;
  pointer-events: none;
}

.event-scheduler .event, .active-event-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10PX 20px;
  background-color: $calendar-dark-gray;
  color: white;
  cursor: grab;
  user-select: none;

  &.active {
    opacity: 0.5;
  }

  &.event-marketing {
    background-color: $calendar-red;
  }

  &.event-operations {
    background-color: $calendar-cyan;
  }

  &.event-financial {
    background-color: $calendar-green;
  }

  &.event-maintenance {
    background-color: $calendar-purple;
  }

  &.event-communications {
    background-color: $calendar-salmon;
  }

  .icon {
    margin-right: 10px;
    img {
      height: 20px;
    }
  }

  .text {
    font-size: 14px;
  }
}