
#navBtn {
  position: relative;
  height: 50px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: right;
}

.nav-lines {
  background: $bb-green;
  height: 3px;
  width: 30px;
  position: relative;
  top: 18px;
  border-radius: 8px;

  &:before,
  &:after {
    position: absolute;
    top: 18px;
    right: 0;
    width: 30px;
    height: 3px;
    background: $bb-green;
    content: '';
    transform: rotate(0);
    transition: all 0.1s ease-in-out;
    border-radius: 8px;
  }

  &:before {
    top: -8px;
    width: 30px;
  }

  &:after {
    top: 8px;
    width: 30px;
    margin-left: 13px;
  }
}

.navbar-toggler:not(.collapsed) {
  .nav-lines {
    background: transparent;
    z-index: 999;

    &:before {
      transform: rotate(-45deg);
      top: 2px;
    }

    &:after {
      transform: rotate(45deg);
      top: 2px;
      margin-left: 0;
    }
  }
}