.points-text{
  padding: 30px 0 20px;
}

.present-img{
  margin: 20px auto -70px;
}

.teal-bg{
  background-color: $bb-teal;
}


z-index-1{
  z-index: 1;
}