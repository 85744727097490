
$mib-note-font: Helvetica, Aria, sans-serif;
$min-note-color: #607D8B;


#mib-header
{
    @include make-container();
    margin-top: 24px;

    & .mib-store-image
    {
        
        @include media-breakpoint-up(sm)
        {
            @include make-col(3);
        }
        @include media-breakpoint-up(md)
        {
            @include make-col(2);
            
        }
        & img
        {
            width: 88px;
            height: 88px;
        }
    }

    & .mib-store-meta
    {
        display: flex;
        align-items: center;
        
        @include media-breakpoint-up(sm)
        {
            @include make-col(13);
        }
        @include media-breakpoint-only(md)
        {
            @include make-col(16);
        }

        .form-select
        {
            padding-top:0;
            padding-bottom:0;
        }

        select
        {
            background-color:$white;
            width: auto;
        }
    }

    & .table-filters
    {
       
        @include media-breakpoint-down(sm)
        {
            @include make-col(16);
            border:1px solid red;
        }
        @include media-breakpoint-only(md)
        {
            @include make-col(12);
        }
    }

    & .table-actions
    {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        @include media-breakpoint-down(sm)
        {
            @include make-col(16);
        }
        @include media-breakpoint-only(md)
        {
            @include make-col(4);

            & button
            {
                display:flex;
                white-space: nowrap;
                font-size: size(12);
                justify-content: center;
                align-content: center;
                align-items: center;
                line-height: 1.5rem;
                padding-left: 0;
                padding-right: 0;

                & img
                {
                    margin-left: 5px;
                    height: 12px;
                }
            }
        }

        

    }

}

#mib
{
    @include make-container();
    --bs-gutter-x: 0;
    margin-top: 24px;

    & .mib-store-image
    {

        @include media-breakpoint-up(sm)
        {
            @include make-col(2);

            & img
            {
                width: 88px;
                height: 88px;
            }
        }
    }


    & .note-section
    {
        background-color: $white;
        border-radius: 0px 0px 12px 12px;
        padding:0;
    }

    & .collapsible-table
    {
        margin-bottom: 24px;
    }

    & .note-info
    {
        padding: 24px;

        & p
        {
            font: normal size(14) $mib-note-font;
            line-height: 1.5rem;
            color: $min-note-color;
        }
        & span
        {

            color: $bb-green;
        }

    }
    & .note-respond
    {
        a
        {
            font: normal size(14) $mib-note-font;
            text-decoration:none;
        }
    }

    &.mib-positive .collapsible-table .table-section .header {
        background: $bahama-green;
    }

    &.mib-negative .collapsible-table .table-section .header {
        background: $bahama-orange;
    }

}