// UTILS
.btn-clean {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.content-section-light {
  margin: 20px 0 0 0;
  box-shadow: 0 0 15px #D7DBE0;
  border-radius: 8px;
  background-color: white;
}

.link-btn {
  text-decoration: none;
}

.bb-green {
  font-family: $tenso;
  color: $bb-green;
}
