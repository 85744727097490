//news picture grid
.news-grid {
    .news-item {
        cursor: pointer;
        margin-bottom: 1rem;
        img {
            max-width: 155px;
            height: 165px;
            border-radius: 12px;
            object-fit: cover;
        }
        svg {
            right: 8px;
            top: 8px;
        }
        .descriptor {
            color: $bb-teal;
        }
    }
    h3 {
        font-size: 14px;
    }
    p {
        font-size: 12px;
        color: $bb-slate-light;
    }
}

//news post
a.news-post {
    text-decoration: none;
}
.news-post {
    h3 {
        margin: 0.5rem 0 0;
        font-weight: bold;
        color: $bb-green;
    }
    img {
        border-radius: 6px;
        max-width: 100%;
        height: auto;

        &.pfp {
            width: 32px;
            height: 32px;
            border-radius: 50px;
        }
    }
    p:last-child {
        margin-bottom: 0;
    }
    svg {
        cursor: pointer;
    }
}

//news sign up
.sign-up {
    label, p {
        font-size: 14px;
        font-family: $tenso;
        color: $bb-green-alt;
    }
    .form-check-input {
        border: 2px solid #A2D7CE;
        width: 18px;
        height: 18px;
        &:checked {
            border-color: $bb-teal;
        }
    }
    .form-check-label {
        font-family: $font-family-sans-serif;
        font-weight: 500;
    }
    button {
        padding: 11px;
    }
}